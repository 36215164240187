<template :key="guid">

  <DxScrollView showScrollbar="always">        

    <div class="card" style="margin: 15px;">
  <div class="card-body">
    <div class="dyn-readonly-form">

      <DxButton
      type="normal"
      icon="contentlayout"
      :text="elementId"
      :visible="formDesignerVisible"
      @click="onClickFormDesign"
    />

    <DxForm
      :formData="formDataItem"
      :readOnly="true"
      :width="button.popup_width"
    >
      <DxGroupItem
        v-for="g in groups.filter((g) => g.in_tab_page == false)"
        :key="g"
        :caption="g.caption"
        :colCount="g.col_count"
        :colSpan="g.col_span"
        :visibleIndex="g.visible_index"
      >
        <DxItem v-for="item in items.filter((i) =>i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id)"
          :key="item.parameter_name"
          :dataField="item.parameter_name"
          :visible="item.visible"
          :visibleIndex="item.visible_index"
          :label="{
            text: item.label_text,
            visible: item.label_visible,
          }"
          :editorType="item.dx_editor_type"
          :helpText="item.help_text"
          :colSpan="item.col_span"
        >
        
        </DxItem>
      </DxGroupItem>

 <!-- TAB NESNESİ -->
      <DxTabbedItem
          v-for="t in tabs"
          :key="t"
          :colCount="t.col_count"
          :colSpan="t.col_span"
          :visibleIndex="t.visible_index"          
        >
        <DxTabPanelOptions
          :swipeEnabled="false"
          :showNavButtons="false"
          :animationEnabled="true"
        />
          <DxTab
            v-for="p in tabPages.filter((i) =>i.dyn_data_grid_button_form_tab_id ==t.dyn_data_grid_button_form_tab_id)"
            :key="p"
            :title="p.title"
          >
            <!-- TAB PAGE NESNELERİ BAŞLANGIÇ -->

            <DxGroupItem
              v-for="g in groups.filter((g) => g.dyn_data_grid_button_form_id ==p.dyn_data_grid_button_form_id)"
              :key="g"
              :caption="g.caption"
              :colCount="g.col_count"
              :colSpan="g.col_span"
              :visibleIndex="g.visible_index"
            >
              <DxItem v-for="item in items.filter((i) =>i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id)"
                :key="item.parameter_name"
                :dataField="item.parameter_name"
                :visible="item.visible"
                :visibleIndex="item.visible_index"
                :label="{
                  text: item.label_text,
                  visible: item.label_visible,
                }"
                :editorType="item.dx_editor_type"
                :helpText="item.help_text"
                :colSpan="item.col_span"
              >
              </DxItem>
            </DxGroupItem>           

            <!-- TAB PAGE NESNELERİ SON -->
          </DxTab>
        </DxTabbedItem>



    </DxForm>
  </div>
  </div>
</div>


  </DxScrollView> 


  <DxPopup
    :key="'GridDetayFormDesignerPopup_' + guid"
    v-model:visible="isFormDesignerPopupVisible"
    :showCloseButton="true"
    :wrapperAttr="formDesignerPopupStyle"
    :title="'ELEMENT ID = ' + elementId"
    :fullScreen="true"        
    @showing="onShowing"
    @hiding="onHiding"
  >
  
  <DxPosition at="center top" my="center top" offset="0 58" :of="'#dynTabPage' + tabPageId" />
    <div>
      <FormDesignerComponent
        v-if="isFormDesignerPopupVisible"
        tip="F"
        :formId="elementId"
      />
    </div>
  </DxPopup>

</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxForm, DxGroupItem, DxItem, DxTabbedItem,DxTabPanelOptions, DxTab } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import CoreService from "@/services/core.service";
import {useAuthStore} from "@/services/auth.store";
import { ref } from "@vue/reactivity";

export default {
  components: {
    DxScrollView,
    DxForm,
    DxGroupItem,
    DxTabbedItem,
    DxTabPanelOptions, 
    DxTab,
    DxItem,
    DxButton,
    DxPopup, DxPosition,
  },
  props: {
    pageId: Number,
    pGridButtonId: Number,
    pMasterItem: Object,
  },

  setup(props) {
   
    
    const guid = ref(CoreService.getRandomGuid());
    const tabPageId = ref(props.pageId);
    const elementId = ref(props.pGridButtonId);

    const authStore = useAuthStore();
    const formDesignerVisible = ref(authStore.formDesignerVisible);
    const isFormDesignerPopupVisible = ref(false);

    const formDataItem = ref({});
    const button = ref({});
    const groups = ref([]);
    const items = ref([]);

    const tabs = ref([]);
    const tabPages = ref([]);

    CoreService.get("GetDataGridForm/" + props.pGridButtonId).then(
      (resultForm) => {
        button.value = resultForm.button[0];
        groups.value = resultForm.groups;
        tabs.value = resultForm.tabs;
        tabPages.value = resultForm.tab_pages;
        items.value = resultForm.items;

        const prm = props.pGridButtonId + "/" + props.pMasterItem.fieldValue;
        CoreService.get("GetGridDetayFormData/" + prm).then((resultData) => {
          formDataItem.value = resultData.result[0];
        });
      }
    );

    function onClickFormDesign() {
      isFormDesignerPopupVisible.value = true;
    }

    function onShowing(){
      const divElement = document.getElementById('dynTabPage' + tabPageId.value);      
      divElement.style.height = "100vh";
    }

    function onHiding(){
      const divElement = document.getElementById('dynTabPage' + tabPageId.value);
      divElement.style.removeProperty("height");
    }

    return {
      guid,
      tabPageId,
      elementId,
      button,
      groups,
      tabs,
      tabPages,
      items,
      formDataItem,
      onClickFormDesign,
      formDesignerVisible,
      isFormDesignerPopupVisible,
      onShowing,
      onHiding,
      formDesignerPopupStyle: {
        class: "form-designer-popup-content-background",
      },
    };
  },
};
</script>


<style>
.dyn-readonly-form input, textarea{
  color: black !important;
}
</style>