<template>
  <DxHtmlEditor
  ref="htmlEditorElement"
    :height="height+'px'"
    @valueChanged="onValueChanged"
  >
    <DxToolbar :multiline="true">
      <DxItem name="undo" />
      <DxItem name="redo" />
      <DxItem name="separator" />
      <DxItem
        name="size"
        :accepted-values="[
          '8pt',
          '10pt',
          '12pt',
          '14pt',
          '18pt',
          '24pt',
          '36pt',
        ]"
        :options="{ inputAttr: { 'aria-label': 'Font size' } }"
      />
      <DxItem
        name="font"
        :accepted-values="[
          'Arial',
          'Courier New',
          'Georgia',
          'Impact',
          'Lucida Console',
          'Tahoma',
          'Times New Roman',
          'Verdana',
        ]"
        :options="{ inputAttr: { 'aria-label': 'Font family' } }"
      />
      <DxItem name="separator" />
      <DxItem name="bold" />
      <DxItem name="italic" />
      <DxItem name="strike" />
      <DxItem name="underline" />

      <DxItem name="separator" />
      <DxItem name="alignLeft" />
      <DxItem name="alignCenter" />
      <DxItem name="alignRight" />
      <DxItem name="alignJustify" />

      <DxItem name="separator" />
      <DxItem name="orderedList" />
      <DxItem name="bulletList" />

      <DxItem name="separator" />
      <DxItem name="color" />
      <DxItem name="background" />

      <DxItem name="separator" />
      <DxItem name="clear" />
      <DxItem name="codeBlock" />
      <DxItem name="blockquote" />
      
      <DxItem name="separator" />
      <DxItem name="insertTable" />
      <DxItem name="deleteTable" />
      <DxItem name="insertRowAbove" />
      <DxItem name="insertRowBelow" />
      <DxItem name="deleteRow" />
      <DxItem name="insertColumnLeft" />
      <DxItem name="insertColumnRight" />
      <DxItem name="deleteColumn" />
    </DxToolbar>
  </DxHtmlEditor>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onMounted  } from "vue";
import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

export default {
  components: {
    DxHtmlEditor,
    DxToolbar,
    DxItem,
  },
  props: {
    pItem: { type: Object },
    pFormData: { type: Object },
  },
  setup(props) {
    const item = reactive(props.pItem);
    const data = reactive(props.pFormData);
    const htmlEditorElement = ref();
    const height = ref(200);
    if(item.height != null){
      height.value = item.height;
    }
    

    function onValueChanged(e) {
      data[item.parameter_name] = e.value;
    }

    onMounted(() => {   
      htmlEditorElement.value.instance.option("value",data[item.parameter_name]);
     })

    return {
      htmlEditorElement,
      item,
      data,
      height,
      onValueChanged,
    };
  },
};
</script>
