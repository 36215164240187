<template :key="guid">
  <iframe :style="style" :src="src"></iframe> 
</template>

<script>

import { ref  } from "vue";
import CoreService from "@/services/core.service";

export default {
  props: {
    report_url: {
      type: String,
      required: true,
    },
    sag_panel: {
      type: Boolean,      
    }      
  },
  setup(props) {

    const guid = ref(CoreService.getRandomGuid());   
    const src = ref(props.report_url+'?sagPanel='+(props.sag_panel ?? false));
    const style = ref("width: 100%; border: 0; height:"+(window.outerHeight- 250)+"px;");
    return {
      guid,    
      src,
      style    
    };
  },
  components: {},
};
</script>
