<template :key="guid">
  <GridComponent
    v-if="areaItem.element_type == 'datagrid' && isLoaded"
    :elementId="areaItem.dyn_element_id"
    :isDetailGrid="false"
    :pageId="tabPageId"
  />   
</template>

<script>

import { ref, inject  } from "vue";
import CoreService from "@/services/core.service";

export default {
  props: {
    page_id: {
      type: String,
      required: true,
    },
    page_title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const globalData = inject('globalData');    
    const guid = ref(CoreService.getRandomGuid());
    const pageItem = ref({});
    const areaItem = ref({});
    const isLoaded = ref(false);
    const tabPageId = ref(props.page_id);

    CoreService.get("GetPageAreaItems/" + props.page_id).then((result) => {
      pageItem.value = result.result.page_item;

      //TODO:çoklu layout için tüm satırlar alınacak, ileride bakılacak
      areaItem.value = result.result.area_items[0];

      isLoaded.value = true;
    });

    return {
      guid,
      tabPageId,
      isLoaded,
      pageItem,
      areaItem,
      globalData
    };
  },
  components: {},
};
</script>
