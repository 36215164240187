<template>
  <div class="dx-swatch-additional side-navigation-menu" @click="forwardClick">
    <div class="menu-container">
      <DxScrollView :height="windowInnerHeight + 'px'" showScrollbar="always">
        <dx-tree-view
          keyExpr="guid"
          :items="menuItems"
          selectionMode="single"
          :searchEnabled="true"
          :focusStateEnabled="false"
          expandEvent="click"
          @itemClick="onItemClick"
          width="100%"
        />
      </DxScrollView>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import DxScrollView from "devextreme-vue/scroll-view";
import DxTreeView from "devextreme-vue/tree-view";
import CoreService from "@/services/core.service";
import { useTabPanelStore } from "@/services/tab-panel.store";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/services/auth.store";
import notify from "devextreme/ui/notify";

export default {
  props: {
    pWindowInnerHeight: Number,
  },
  setup(props) {
    const windowInnerHeight = ref(props.pWindowInnerHeight);
    const router = useRouter();
    const store = useAuthStore();
    const tabPanelStore = useTabPanelStore();
    const userName = ref(store.getUser.adSoyad);
    const menuItems = ref([]);
    getMenuler();

    function getMenuler() {
      CoreService.get("GetMenu").then((result) => {
        menuItems.value = result.result.menuler;

        setTimeout(() => {
          //kullanıcı bazlı otomatik açılış sayfaları varsa tab pahe olarak açılır
          acilisSayfalariniUygula(result.result.acilis_sayfalari);
        }, 300);
      });
    }
  
    function acilisSayfalariniUygula(data) {      
      data.forEach((item) => {
        if (item.element_type == "datagrid") {
          tabPanelStore.addPageComponent(item.page_id, item.page_title);
        } else if (item.element_type == "dashboard") {
          tabPanelStore.addDashboardViewerPageComponent(
            item.page_id,
            item.page_title,
            item.element_id
          );
        }
        else if (item.element_type == "rapor") {
          const raporId = item.element_id;
          CoreService.post("ReportDisplayFromMenu/" + raporId).then(
          (result) => {
            if (result.success) {
              //window.open(result.result, "_blank");              
              tabPanelStore.reportDisplay(result.result.url,result.result.report_name,true);
            } else {              
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );          
        }
      });

      if (data.length > 1) 
      {
        tabPanelStore.setSelectedIndex(0);
      }
    }

    function onItemClick(e) {
      
      const item = e.itemData;
      if (!item.page_id) return;

      if (item.element_type == "datagrid") {
        tabPanelStore.addPageComponent(item.page_id, item.page_title);
      } else if (item.element_type == "dashboard") {
        tabPanelStore.addDashboardViewerPageComponent(
          item.page_id,
          item.page_title,
          item.element_id
        );
      }
      else if (item.element_type == "rapor") {
          const raporId = item.element_id;
          CoreService.post("ReportDisplayFromMenu/" + raporId).then(
          (result) => {
            if (result.success) {
              //window.open(result.result, "_blank");              
              tabPanelStore.reportDisplay(result.result.url,result.result.report_name,true);
            } else {              
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );          
        }
    }

    function onCikisYap() {
      store.logout().then(() => {
        router.push("/login");
      });
    }

    return {
      menuItems,
      onItemClick,
      onCikisYap,
      userName,
      windowInnerHeight,
    };
  },
  components: {
    DxTreeView,
    // DxButton,
    // DxToolbar,
    // DxItem,
    DxScrollView,
  },
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;
}
</style>
