<template :key="guid">
  <DxDropDownBox
    ref="dropDownElement"
    :dataSource="selectedRowsData"
    :value="selectedRowKeys"
    :deferRendering="false"
    :showClearButton="true"
    :displayExpr="parametreItem.lookup_display_field"
    :valueExpr="parametreItem.lookup_value_field"
    @valueChanged="dropDownValueChanged"
    v-model:opened="dropDownOpened"
    placeholder="..."
    :disabled="dropDownIsDisabled"
  >
    <template #content>
      <DxDataGrid
        ref="dataGridElement"
        :dataSource="dataSource"
        :showBorders="true"
        :remoteOperations="true"
        :hoverStateEnabled="true"
        :height="400"
        @selectionChanged="gridSelectionChanged"
        :filterValue="filterValue"
        
      >
        <DxEditing
          :allowUpdating="false"
          :allowAdding="false"
          :allowDeleting="false"
        />
        <DxSelection
          :mode="parametreItem.lookup_multiple_selection ? 'multiple' : 'single'"
          :allowSelectAll="false"
        />
        <DxPaging :enabled="true" :pageSize="10" />
        <DxFilterRow :visible="true" />
        <DxScrolling mode="virtual" />

        <DxColumn
          v-for="c in columns"
          :key="c.data_field"
          :dataField="c.data_field"
          :dataType="c.dx_data_type"
          :caption="c.caption"
          :format="c.format"
          :visible="c.visible"
          :visibleIndex="c.visible_index"
        />
      </DxDataGrid>
    </template>
  </DxDropDownBox>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { watch } from "vue";
import CoreService from "@/services/core.service";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxEditing,
} from "devextreme-vue/data-grid";

export default {
  components: {
    DxDropDownBox,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxEditing,
  },
  props: {
    customTemplatePrefix: String,
    parametreFormItem: {
      type: Object,
      required: true,
    },
    parametreFormItemData: {
      type: Object,
      required: true,
    },
    formDataItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {    

    const guid = ref(CoreService.getRandomGuid());
    const dropDownElement = ref();
    const dataGridElement = ref();
    const dropDownIsDisabled = ref(false);
    let dataItem = reactive(props.formDataItem);
    const parametreItem = reactive(props.parametreFormItem);
    //const isOtomatikTetikle = parametreItem.otomatik_tetikle;
    const dropDownOpened = ref(false);
    const isLoaded = ref(false);

    const selectedRowKeys = ref([]);
    const selectedRowsData = ref([]);

    const columns = ref([]);

    //lookup sql where parametresi almak için url querystring ile kullanılıyor
    const lookupQueryParams =
      "?parametreLookupButtonFormItemId=" +
      props.parametreFormItem.dyn_data_grid_parametre_form_item_id;

    const dataSource = ref(
      CoreService.customStore(
        null,
        "GetList/" + parametreItem.lookup_dyn_data_grid_id + lookupQueryParams
      )
    );

    //default value girilmiş ise serverden çekilip seçili hale getirilir
    if (parametreItem.default_value != null) {
      
      CoreService.get(
        "GetList/" + parametreItem.lookup_dyn_data_grid_id + lookupQueryParams
      ).then((result) => {
        
        selectedRowsData.value = result.result.data;
        selectedRowKeys.value.push(getColumnDefaultValue(parametreItem));
      });
    }

    function getColumnDefaultValue(item) {
      if (item.default_value == null) {
        return null;
      }

      if (item.dx_data_type == "boolean") {
        return item.default_value === "true";
      }

      if (item.dx_data_type == "number") {
        return parseFloat(item.default_value);
      }
      return item.default_value;
    }

    const filterValue = ref();

    if (parametreItem.cascade_form_item_id != null) {
      
      const cascadeItem = props.parametreFormItemData.find(
        (d) =>
          d.dyn_data_grid_parametre_form_item_id ==
          parametreItem.cascade_form_item_id
      );

      filterValue.value = null;
      dropDownIsDisabled.value = true;

      //default değer var ise ilk filtreleme uygulanır
      if(dataItem[cascadeItem.parameter_name] != null)
        {
          filterValue.value = [
              parametreItem.cascade_filter_field,
              "=",
              dataItem[cascadeItem.parameter_name],
            ];
            dropDownIsDisabled.value = false;
        }
      
      watch(
        () => dataItem[cascadeItem.parameter_name],
        (newValue) => {
          dropDownElement.value.instance.option("value", null);

          if (newValue == null) {
            dropDownIsDisabled.value = true;
          } else {
            filterValue.value = [
              parametreItem.cascade_filter_field,
              "=",
              newValue,
            ];
            dropDownIsDisabled.value = false;
          }
        },
        { deep: true }
      );
    }

    CoreService.get(
      "GetDataGrid/" + parametreItem.lookup_dyn_data_grid_id
    ).then((result) => {
      columns.value = result.columns;
      isLoaded.value = true;
    });

    function gridSelectionChanged(e) {
      selectedRowsData.value = e.selectedRowsData;
      selectedRowKeys.value = e.selectedRowsData.map(
        (x) => x[parametreItem.lookup_value_field]
      );

      if (parametreItem.lookup_multiple_selection == false) {
        dropDownOpened.value = false;
      }
    }

    function dropDownValueChanged(e) {
      if (e.value == null || e.value.length == 0) {
        dataItem[parametreItem.parameter_name] = null;
        dataGridElement.value.instance.clearSelection();
      } else {
        if (parametreItem.lookup_multiple_selection) {
          dataItem[parametreItem.parameter_name] = e.value;
        } else {
          dataItem[parametreItem.parameter_name] = e.value[0];
        }
      }
    }

    return {
      guid,
      parametreItem,
      dropDownElement,
      dataGridElement,
      dropDownIsDisabled,
      dataSource,
      filterValue,
      dropDownOpened,
      selectedRowKeys,
      selectedRowsData,
      columns,
      isLoaded,
      gridSelectionChanged,
      dropDownValueChanged,
    };
  },
};
</script>
