import axios from "axios";
//import appInfo from "@/app-info";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";




let API_URL = "";//config.apiUrl;

const OTURUM_SURE_MSG = "OTURUM SÜRENİZ DOLMUŞ. LÜTFEN TEKRAR GİRİŞ YAPINIZ.";

class CoreService {

  setApiUrl(url) {
    console.log("setApiUrl:",url);
    API_URL = url;
  }

  getApiUrl() {
    return API_URL;
  }

  getToken(){
    const user = JSON.parse(localStorage.getItem("user"));
    return user.token;
  }

  getAuthHeader(addMultiPart = false,isFileDownload=false) {
    let h = {};
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      if (addMultiPart) {
        h = {
          headers: {
            token: user.token,
            "Content-Type": "multipart/form-data",
          },
        };
      } if (isFileDownload) {
        h = {
          headers: {
            token: user.token,
          },
        };
      }
      else {
        h = {
          headers: {
            token: user.token,
          },
        };
      }
    }
    return h;
  }

  get(url, headerParametreler = {}) {
    let h = this.getAuthHeader(false);

    /*
    gridParametreler:
    grid nesnesinin hemen üstünde açılan form ile 
    parametre girişi ile sorgulama yapmak için kullanılan querystring'ler
    */
    h.params = { gridParametreler: JSON.stringify(headerParametreler) };

    return axios.get(API_URL + url, h).then(
      (response) => {
        this.getNotifications(response);
        return response.data;
      },
      (error) => {
        if (error.response.status == 401) {
          notify(
            {
              message: OTURUM_SURE_MSG,
              type: "error",
            },
            { position: "bottom center", direction: "up-push" }
          );
          return Promise.reject(OTURUM_SURE_MSG);
        }
        notify(
          {
            message: error.message,
            type: "error",
          },
          { position: "bottom center", direction: "up-push" }
        );
        return Promise.reject(error.message);
      }
    );
  }

  delete(url) {
    const header = this.getAuthHeader();
    return axios.delete(API_URL + url, header).then(
      (response) => {
        this.getNotifications(response);
        return response.data;
      },
      (error) => {
        if (error.response.status == 401)
          return Promise.reject(OTURUM_SURE_MSG);
        return Promise.reject(error.message);
      }
    );
  }

  post(url, params = {}, addMultiPart = false) {
    const header = this.getAuthHeader(addMultiPart);
    return axios.post(API_URL + url, params, header).then(
      (response) => {
        this.getNotifications(response);        
        return response.data;
      },
      (error) => {       
        // if (error.response.status == 401)
        //   return Promise.reject(OTURUM_SURE_MSG);
        // return Promise.reject(error.message);
        let msg = error.message;
        if (error.response?.status == 401) {
          msg = OTURUM_SURE_MSG;        
        }
        return {success:false,message:msg};
      }
    );
  }

  postFile(url, files) {
    const header = this.getAuthHeader(true);
    return axios.post(API_URL + url, files, header).then(
      (response) => {
        return response.result;
      },
      (error) => {
        if (error.response?.status == 401)
          return Promise.reject(OTURUM_SURE_MSG);
        return Promise.reject(error.message);
      }
    );
  } 

  put(url, params = {}) {
    const header = this.getAuthHeader();
    return axios.put(API_URL + url, params, header).then(
      (response) => {
        this.getNotifications(response);
        return response.data;
      },
      (error) => {
        if (error.response.status == 401)
          return Promise.reject(OTURUM_SURE_MSG);
        return Promise.reject(error.message);
      }
    );
  }

  customStoreAutoComplete(key, loadUrl, params) {
    const store = new CustomStore({
      key: key,
      load: () => {
        const p = "?" + new URLSearchParams(params).toString();
        const result = this.get(loadUrl + p).then((d) => {
          if (d.success) return d.result;
          return Promise.reject(d.message);
        });
        return result;
      },
    });

    return store;
  }

  customStoreLookup(loadUrl) {
    return new CustomStore({
      loadMode: "raw",
      load: () => {
        const result = this.get(loadUrl).then((d) => {
          if (d.success) return d.result;
          return Promise.reject(d.message);
        });
        return result;
      },
    });
  }

  customStore(key, loadUrl, headerParametreler = {}, onLoaded = null) {
    const store = new CustomStore({
      key: key,
      load: (loadOptions) => {
        let onEk = "?";
        if (loadUrl.includes("?")) {
          onEk = "&";
        }
        let params = onEk;

        try {
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary",
          ].forEach((i) => {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
          });
        } catch (err) {
          console.log(err);
        }

        function isNotEmpty(value) {
          return value !== undefined && value !== null && value !== "";
        }

        params = params.slice(0, -1);

        const result = this.get(loadUrl + params, headerParametreler).then(
          (d) => {
            if (d.success) {
              return d.result;
            }
            return Promise.reject(d.message);
          }
        );
        return result;
      },
      onLoaded: onLoaded,
    });

    return new DataSource({
      store: store,
      langParams: {
        locale: "tr",
        collatorOptions: {
          sensitivity: "accent",
          caseFirst: "upper",
        },
      },
    });

    //return store;
  }

  getDataSource(customStore, filter) {
    return new DataSource({
      store: customStore,
      filter: filter,
    });
  }

  downloadFile(url, fullFileName) {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios({
      url: API_URL + url,
      headers: { token: user.token },
      method: "GET",
      responseType: "blob",
    }).then((response) => {     

      const blobData = new Blob([response.data], { type: response.data.type });     

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blobData);
      link.setAttribute("download", fullFileName);
      document.body.appendChild(link);
      link.click();
      link = null;
    });
  }

  getPdfFileIframe(guid,dosyaId) {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios({
      url: API_URL + 'GetFileBase64/'+guid+'/'+dosyaId,
      headers: { token: user.token },
      method: "GET"
    }).then((response) => {
      const iframe = document.createElement('iframe');
      iframe.src = 'data:application/pdf;base64,' + response;
      iframe.style.width = '100%';
      iframe.style.height = '500px';
      return iframe;
    });
  }

  getRandomGuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  getNotifications(response){    
    if (response.data.notices != null && response.data.notices.length > 0) {
      response.data.notices.forEach((item) => {
        notify(
          {
            type:'success',
            message: item.noticeText,
            displayTime: 10000,
          },
          { position: "bottom right", direction: "up-push" }
        );
      });
    }
  }

}

export default new CoreService();
