<template>
    <DxTabPanel :dataSource="groupItems">
      <template #title="{ data: item }">
        <span>{{ item.groupId }}</span>
      </template>     

      <template #item="{ data: item }">
        <DxForm
          :formData="item"
          labelMode="floating"
          :showColonAfterLabel="true"
          :labelLocation="left"
          :colCount="4"          
        >
          <DxSimpleItem data-field="caption" />
          <DxSimpleItem data-field="visibleIndex" />
          <DxSimpleItem data-field="colCount" />
          <DxSimpleItem data-field="colSpan" />
          <DxSimpleItem :colSpan="4">
            <div style="float: left; padding-top: 5px">
              <DxButton
                icon="add"
                type="success"
                hint="YENİ GRUP EKLE"
                @click="onGrupEkle"
              />
            </div>
            <div style="float: right; padding-top: 5px">
              <DxButton
                icon="remove"
                type="danger"
                hint="SİL"
                @click="onGrupSil(item.groupId)"
              />
            </div>
          </DxSimpleItem>
        </DxForm>
      </template>
    </DxTabPanel>
</template>

<script>
import { ref } from "vue";
import { DxForm, DxSimpleItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxTabPanel from "devextreme-vue/tab-panel";
import CoreService from "@/services/core.service";
import notify from "devextreme/ui/notify";
import { confirm } from "devextreme/ui/dialog";



export default {
  components: { DxTabPanel, DxForm, DxSimpleItem, DxButton },
  props: {
    pTip: String,
    pFormId: Number,
    pGroupItems: Object,
    pItems: Object,
  },
  setup(props, { emit }) {
    const groupItems = ref(props.pGroupItems);

    const items = ref(props.pItems);

    function onGrupSil(groupId) {
      let result = confirm(
        "<i>" + groupId + " numaralı kayıt silinecek?</i>",
        "Uyarı"
      );
      result.then((dialogResult) => {
        if (!dialogResult) return;
        let index = -1;
        for (const i in groupItems.value) {
          if (groupItems.value[i].groupId !== parseInt(groupId)) continue;
          index = i;
        }
        CoreService.delete(
          "DeleteFormGroup/" + props.pTip + "/" + groupId
        ).then((result) => {
          if (result.success) {
            emit("callSetComponentRender", false);
            groupItems.value.splice(index, 1);
            silinenGrupdakiItemlariTasi(groupId);
            emit("callSetComponentRender", true);
            notify("Grup silindi", "success");
          } else {
            notify(result.message, "error", 10000);
          }
        });
      });
    }

    function silinenGrupdakiItemlariTasi(groupId) {
      for (let i in items.value) {
        if (items.value[i].groupId !== parseInt(groupId)) continue;

        items.value[i].visible = false;
        items.value[i].visibleIndex = null;
        items.value[i].groupId = null;
      }
    }

    function onGrupEkle() {
      CoreService.post("AddFormGroup/" + props.pTip + "/" + props.pFormId).then(
        (result) => {
          if (result.success) {
            emit("callSetComponentRender", false);
            groupItems.value.push(result.result);
            emit("callSetComponentRender", true);
            notify("Yeni grup eklendi", "success");
          } else {
            notify(result.message, "error", 10000);
          }
        }
      );
    }
   
    return {
      groupItems,
      onGrupSil,
      onGrupEkle,     
    };
  },
};
</script>