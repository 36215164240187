<template>
  <div>
    <DxScrollView showScrollbar="always" height="700px">
      <div
        style="background: rgba(24, 22, 22, 0.116); min-height: 700px; padding-bottom: 50px;padding-top: 5px;"
        @dragover.prevent
        @drop="onDropHiddenArea"
      >
        <div
          v-for="item in items.filter((d) => d.visible == false)"
          :key="item.itemId"
          :draggable="true"
          @dragstart="onDragStart($event, item.itemId)"
          class="form-item dx-card"
        >
          {{ item.parameterName }}
        </div>
      </div>
    </DxScrollView>
  </div>
</template>

<script>
import { ref } from "vue";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  components: { DxScrollView },
  props: {
    pItems: Object,
  },
  setup(props, { emit }) {
    const items = ref(props.pItems);

    function onDragStart(e, itemId) {      
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("itemId", parseInt(itemId));     
    }

    async function onDropHiddenArea(e) {
      emit("callSetComponentRender", false);
      const dragItemId = parseInt(e.dataTransfer.getData("itemId"));
      const index = getItemIndex(dragItemId);
      items.value[index].visible = false;
      emit("callSetComponentRender", true);
    }

    function getItemIndex(itemId) {
      for (const i in items.value) {
        if (items.value[i].itemId !== parseInt(itemId)) continue;
        return i;
      }
    }

    return {
      items,
      onDragStart,
      onDropHiddenArea,
    };
  },
};
</script>
