<template :key="guid">
<DxFileUploader
            ref="ebysFileUploaderElement"
            selectButtonText="YÜKLEMEK İÇİN EVRAK SEÇİN"      
            uploadMode="useForm" 
            :multiple="false"             
            @valueChanged="onValueChanged"
          /> 
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxFileUploader
  },

  props: {
    buttonFormItem: { type: Object },
    buttonFormItemData: { type: Object },
    formDataItem: { type: Object },
  },

  setup(props) {   
    const guid = ref(CoreService.getRandomGuid());
    const ebysFileUploaderElement = ref();
    let dataItem = reactive(props.formDataItem);
    const buttonItem = reactive(props.buttonFormItem);   

   function onValueChanged(e){
    
    const reader = new FileReader();
    reader.readAsDataURL(e.value[0]);
    reader.onloadend = function () {      
      dataItem[buttonItem.parameter_name] = {name:e.value[0].name,type:e.value[0].type,base64:reader.result};
    };


   }   

    return {
      guid,
      ebysFileUploaderElement,
      buttonItem,
      onValueChanged
     
    };
  },
};
</script>

<style></style>
