<template>
  <DxForm v-if="isLoaded">
    <DxGroupItem :colCount="2">
      <DxItem>
        <DxDataGrid
          ref="gridElement"
          :dataSource="dataSource"
          :showBorders="true"
          :show-column-lines="true"
          :remoteOperations="true"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="400"
          :width="gridItem.width"
          :hoverStateEnabled="true"
          :wordWrapEnabled="gridItem.word_wrap_enabled"
          @rowClick="onRowClick"
          @rowDblClick="onRowDblClick"
        >
          <DxEditing
            :allowUpdating="false"
            :allowAdding="false"
            :allowDeleting="false"
          />
          <DxFilterRow :visible="true" />

          <DxPaging :page-size="gridItem.paging_page_size ?? 10" />         

          <DxColumn type="buttons" :width="50">
            <GridButton icon="add" @click="onAddForm" />
          </DxColumn>
          <DxColumn
            v-for="item in columns"
            :key="'dxcolumn_' + item.dyn_data_grid_column_id"
            :dataField="item.data_field"
            :dataType="item.dx_data_type"
            :caption="item.caption"
            :format="item.format"
            :visible="item.visible"
            :visibleIndex="item.visible_index"
            :name="item.data_field"
            :allowFiltering="item.allow_filtering"
            :allowSorting="item.allow_sorting"
            :sortOrder="item.sort_order"
            :sortIndex="item.sort_index"
            :alignment="item.alignment"
            :encodeHtml="false"
          />
        </DxDataGrid>
      </DxItem>
      <DxItem>
        <DxDataGrid
          ref="selectionGridElement"
          :dataSource="selectionDataSource"
          :showBorders="true"
          :show-column-lines="true"
          :remoteOperations="false"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="400"
          :width="gridItem.width"
          :hoverStateEnabled="true"
          :wordWrapEnabled="gridItem.word_wrap_enabled"
        >
          <DxEditing
            :allowUpdating="false"
            :allowAdding="false"
            :allowDeleting="true"
          />
          <DxFilterRow :visible="true" />

          <DxPaging :page-size="gridItem.paging_page_size ?? 10" />

          <DxColumn type="buttons" :width="50">
            <GridButton name="delete" />
          </DxColumn>
          <DxColumn
            v-for="f in fields"
            :key="f"
            :dataField="f.parameter_name"
            :dataType="f.dx_data_type"
            :caption="f.label_text"
            :visible="f.visible"
            :visibleIndex="f.visible_index"
            :allowFiltering="false"
            :allowSorting="false"
            :format="f.format"
          />

          <DxSummary>
            <DxTotalItem
              v-for="c in totalSummaryColumns"
              :key="c"
              :column="c.data_field"
              :summaryType="c.summary_type"
              :valueFormat="c.value_format"
            />
          </DxSummary>
        </DxDataGrid>
      </DxItem>
    </DxGroupItem>
    <DxGroupItem :colCount="2">
      <DxItem :colSpan="2">
        <div style="text-align: right">
          <DxButton icon="add" text="EKLE" @click="onAddGrid" />
        </div>
      </DxItem>
    </DxGroupItem>
  </DxForm>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onMounted, watch } from "vue";

import {
  DxDataGrid,
  DxColumn,
  DxButton as GridButton,
  DxEditing,
  DxFilterRow,
  DxSummary,
  DxTotalItem,
  DxPaging
} from "devextreme-vue/data-grid";
import { DxForm, DxGroupItem, DxItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import hideToasts from "devextreme/ui/toast/hide_toasts";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    GridButton,
    DxButton,
    DxEditing,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
    DxForm,
    DxGroupItem,
    DxItem,
    DxPaging
  },
  props: {
    pItem: { type: Object },
    pFormData: { type: Object },
    pFields: { type: Object },
  },
  setup(props) {
    const isLoaded = ref(false);
    const item = reactive(props.pItem);
    const data = reactive(props.pFormData);
    const fields = reactive(
      props.pFields.filter((f) => f.multiple_grid_cascade_form_item_id != null)
    );

    const totalSummaryColumns = ref([]);
    for (var i in fields) {
      const colItem = fields[i];
      if (colItem.multiple_grid_summary_types == null) continue;

      for (var j in colItem.multiple_grid_summary_types) {
        const summaryItem = colItem.multiple_grid_summary_types[j];
        totalSummaryColumns.value.push({
          data_field: colItem.parameter_name,
          summary_type: summaryItem,
          value_format: colItem.format,
        });
      }
    }

    const gridElement = ref();
    const selectionGridElement = ref();

    const dataGridId = item.multiple_grid_item_grid_id;

    const gridItem = ref({});
    const columns = ref([]);
    const selectionDataSource = ref([]);

    const dataSource = ref();
    CoreService.get("GetDataGrid/" + dataGridId).then((result) => {
      gridItem.value = result.grid[0] ?? {};
      columns.value = result.columns;
      dataSource.value = CoreService.customStore(null, "GetList/" + dataGridId);
      isLoaded.value = true;
    });

    function onRowClick(e) {
      for (let f of fields) {
        data[f.parameter_name] = e.data[f.parameter_name] ?? null;
      }
      manuelCallValueRule();
    }

    function onRowDblClick(e) {
      for (let f of fields) {
        data[f.parameter_name] = e.data[f.parameter_name] ?? null;
      }
      manuelCallValueRule();
      setTimeout(() => {
        onAddGrid();
      }, 100);
    }

    function onAddForm(e) {
      const item = e.row.data;
      for (let f of fields) {
        data[f.parameter_name] = item[f.parameter_name] ?? null;
      }
      manuelCallValueRule();
    }

    function onAddGrid() {
      if (!validateForm()) {
        return;
      }

      let jsonItem = {};
      for (let f of fields) {
        jsonItem[f.parameter_name] = data[f.parameter_name];
      }
      selectionDataSource.value.push(jsonItem);
      data[item.parameter_name] = selectionDataSource.value;
      selectionGridElement.value.instance.refresh();
      clearForm();
    }

    function manuelCallValueRule() {
      const valueRuleItems = fields.filter(
        (f) => f.value_rule_form_item_names != null
      );
      valueRuleItems.forEach((ruleItem) => {
        data[ruleItem.parameter_name] = eval(ruleItem.value_rule_js_function);
      });
    }

    function valueRuleInit() {
      const valueRuleItems = fields.filter(
        (f) => f.value_rule_form_item_names != null
      );
      valueRuleItems.forEach((ruleItem) => {
        ruleItem.value_rule_form_item_names.forEach((watchItemName) => {
          watch(
            () => data[watchItemName],
            () => {
              data[ruleItem.parameter_name] = eval(
                ruleItem.value_rule_js_function
              );
            }
          );
        });
      });
    }

    function clearForm() {
      for (let f of fields) {
        data[f.parameter_name] = null;
      }
    }

    function validateForm() {
      let dizi = [];
      fields
        .filter(
          (i) => i.visible && i.is_required && i.dx_data_type != "boolean"
        )
        .forEach((f) => {
          if (f.dx_data_type == "number") {
            if (data[f.parameter_name] == null) {
              dizi.push(f.label_text);
            }
          } else if (f.dx_data_type == "object") {
            if (data[f.parameter_name] == null) {
              dizi.push(f.label_text);
            }
          } else {
            if (
              data[f.parameter_name] == null ||
              data[f.parameter_name] == ""
            ) {
              dizi.push(f.label_text);
            }
          }
        });

      if (dizi.length > 0) {
        hideToasts();
      }

      dizi.forEach((d) => {
        notify(
          {
            message: "[ " + d + " ] alanı gereklidir.",
            type: "error",
            displayTime: 15000,
          },
          { position: "bottom right", direction: "up-push" }
        );
      });
      return dizi.length == 0;
    }

    onMounted(() => {
      valueRuleInit();      
      //htmlEditorElement.value.instance.option("value",data[item.parameter_name]);
    });

    return {
      gridElement,
      selectionGridElement,
      dataSource,
      gridItem,
      columns,
      selectionDataSource,
      totalSummaryColumns,
      item,
      data,
      fields,
      isLoaded,
      onRowClick,
      onRowDblClick,
      onAddForm,
      onAddGrid,
    };
  },
};
</script>
