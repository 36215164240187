<template :key="'form_designer_component_' + guid">
  <div class="div-form-designer-container">
    <DxBox direction="row" width="100%" v-if="isLoaded">
      <DxItem :ratio="3">
        <template #default>
          <div @dragover.prevent @drop="onDropFormArea">
            <FormDesignerItemComponent
              v-if="componentRender"
              :pFormItem="form"
              :pGroupItems="groups"
              :pItems="items"
            />
          </div>
        </template>
      </DxItem>
      <DxItem :ratio="1">
        <template #default>
          <div style="padding-left: 10px;">
            <DxButton
              text="DEĞİŞİKLİKLERİ KAYDET"
              type="normal"
              icon="save"
              @click="onKaydet"
            />
            <br />
            <FormDesignerFormComponent
              v-if="componentRender"
              @callSetComponentRender="setComponentRender"
              :pFormItem="form"
            />           
            <br />
            <FormDesignerGroupComponent
              v-if="componentRender"
              @callSetComponentRender="setComponentRender"
              :pGroupItems="groups"
              :pTip="form.tip"
              :pFormId="form.formId"
              :pItems="items"
            />          
            <br />
            <FormDesignerHiddenColumnComponent
              v-if="componentRender"
              @callSetComponentRender="setComponentRender"
              :pItems="items"
            />
          </div>
        </template>
      </DxItem>
    </DxBox>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import { DxBox, DxItem } from "devextreme-vue/box";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import FormDesignerFormComponent from "@/components/form-designer-form.vue";
import FormDesignerGroupComponent from "@/components/form-designer-group.vue";
import FormDesignerHiddenColumnComponent from "@/components/form-designer-hidden-columns.vue";
import FormDesignerItemComponent from "@/components/form-designer-item.vue";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxBox,
    DxItem,
    DxButton,
    FormDesignerFormComponent,
    FormDesignerGroupComponent,
    FormDesignerHiddenColumnComponent,
    FormDesignerItemComponent,
  },
  props: {
    tip: String,
    formId: Number,
  },
  setup(props) {
    const guid = ref(CoreService.getRandomGuid());
    const componentRender = ref(true);
    const isLoaded = ref(false);

    const form = ref({});
    const groups = ref([]);
    const items = ref([]);

    CoreService.get(
      "GetFormDesignerData/" + props.tip + "/" + props.formId
    ).then((result) => {
      form.value = result.result.form;
      groups.value = result.result.groups;
      items.value = result.result.items;
      isLoaded.value = true;
    });

    function onKaydet() {
      const prm = {
        form: form.value,
        groups: groups.value,
        items: items.value,
      };
      CoreService.post("SaveFormDesigner/", prm).then((result) => {
        if (result.success) {
          notify("Yeni grup eklendi", "success");
        } else {
          notify(result.message, "error", 10000);
        }
      });
    }

    async function onDropFormArea(e) {    

      const dragItemId = parseInt(e.dataTransfer.getData("itemId"));

      const groupElement = e.target.closest(".dyn-form-group");

      if (groupElement == null) return;

      let groupId = null;
      groupElement.classList.forEach(function (value) {
        if (value.startsWith("dyn-id")) {
          groupId = parseInt(value.replace("dyn-id-", ""));
        }
      });

      if (groupId == null) return;

      componentRender.value = false;

      const index = getItemIndex(dragItemId);
      items.value[index].visible = true;
      items.value[index].groupId = groupId;
      items.value[index].visibleIndex = getNewVisibleIndex(
        dragItemId,
        groupId,
        true
      );

      updateVisibleIndexByTargetItem(e, dragItemId);

      refreshGroupVisibleIndexs(groupId);

      await nextTick();
      componentRender.value = true;
    }

    function getItemIndex(itemId) {
      for (const i in items.value) {
        if (items.value[i].itemId !== parseInt(itemId)) continue;
        return i;
      }
    }

    function getNewVisibleIndex(dragItemId, groupId) {
      let visibleIndex = 0;
      for (const i in items.value) {
        if (items.value[i].groupId !== parseInt(groupId)) continue;

        if (items.value[i].itemId === parseInt(dragItemId)) continue;

        if (items.value[i].visibleIndex <= visibleIndex) continue;

        visibleIndex = items.value[i].visibleIndex;
      }

      return visibleIndex + 10;
    }

    function updateVisibleIndexByTargetItem(e, dragItemId) {
      const targetColumnElement = e.target.closest(".dyn-column-item");
      const targetItemId = targetColumnElement?.dataset.itemId;
      if (targetItemId == null) return;

      const sagTarafaBirakildi =
        e.screenX - targetColumnElement.getBoundingClientRect().left >
        targetColumnElement.offsetWidth / 2;

      const targetIndex = getItemIndex(targetItemId);
      const targetVisibleIndex = items.value[targetIndex].visibleIndex;
      const newVisibleIndex = sagTarafaBirakildi
        ? targetVisibleIndex + 1
        : targetVisibleIndex - 1;
      const dragIndex = getItemIndex(dragItemId);
      items.value[dragIndex].visibleIndex = newVisibleIndex;
    }

    function refreshGroupVisibleIndexs(groupId) {
      const sortingItems = items.value
        .filter((d) => d.groupId == parseInt(groupId))
        .sort((a, b) => {
          return a.visibleIndex < b.visibleIndex ? -1 : null;
        });

      for (const i in sortingItems) {
        const index = getItemIndex(sortingItems[i].itemId);
        const visibleIndex = (parseInt(i) + 1) * 10;
        items.value[index].visibleIndex = visibleIndex;
      }
    }

    async function setComponentRender(status) {
      if (status) {
        await nextTick();
        componentRender.value = true;
      } else {
        componentRender.value = false;
      }
    }

    return {
      guid,
      isLoaded,
      form,
      groups,
      items,
      onDropFormArea,
      componentRender,
      setComponentRender,
      onKaydet,
    };
  },
};
</script>

<style lang="scss">
.form-designer-popup-content-background .dx-popup-content {
  background-color: #f2f2f2;
}
.div-form-designer-container {
  .dyn-form-group {
    border: 0.2px dotted rgb(160, 158, 158);
    margin: 15px;
  }
  .form-item {
    box-sizing: border-box;
    padding: 10px;
    margin: 9px;
    font-size: 14px;
    position: relative;
  }
  .dx-form-group-with-caption {
    padding-top: 10px;
  }

  .dx-form-group-caption {
    padding-left: 10px;
  }

  .dx-form-group-content {
    padding: 20px 20px 40px 20px !important;
  }
  .dx-field-item {
    padding: 0px !important;
  }
}
</style>
