<template :key="guid">
<small class="dx-field-item-label-text" style="position: absolute;top: 5px;right: 6px;font-size: 10px;">DDGC[{{ dataGridId }}]</small>
  <div v-if="!isLoaded">Loading...</div>

  <div v-else>
    <DxDataGrid
      ref="gridElement"
      :key="'dx-data-grid-' + guid"
      :dataSource="dataSource"
      :showBorders="true"
      :show-column-lines="true"
      :remoteOperations="true"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :filterValue="filterValue"
      :height="400"
      :hoverStateEnabled="true"
      @toolbarPreparing="onToolbarPreparing"
      @rowClick="onRowClick"
    >
      <DxEditing
        :allowUpdating="false"
        :allowAdding="false"
        :allowDeleting="false"
      />
      <DxFilterRow :visible="true" />
      <DxScrolling mode="virtual" />

      <DxColumn type="buttons" v-if="columnButtons.length > 0">
        <DxButton
          v-for="item in columnButtons"
          :key="item"
          :hint="item.caption"
          :icon="item.icon"
          :text="item.caption"          
          @click="onButtonClick($event, item.dyn_data_grid_button_id)"
        />
      </DxColumn>

      <DxColumn
        v-for="item in columns"
        :key="'dxcolumn_' + item.dyn_data_grid_column_id"
        :dataField="item.data_field"
        :dataType="item.dx_data_type"
        :caption="item.caption"
        :format="item.format"
        :visible="item.lookup_visible"
        :visibleIndex="item.visible_index"
        :allowFiltering="item.allow_filtering"
        :allowSorting="item.allow_sorting"
        :sortOrder="item.sort_order"
        :sortIndex="item.sort_index"
        :alignment="item.alignment"
        :encodeHtml="false"
      />

      <DxColumnChooser :enabled="columnChooserEnabled" mode="select"/> 
    </DxDataGrid>

    <slot v-if="isDropDownPopupEnable" :key="'slot_popupForm_' + guid">
      <PopupFormComponent
        :key="'popupForm_' + guid"
        :gridButtonId="popupGridButtonId"
        :popupDataItem="popupDataItem"
        :dataGridFormResult="dataGridFormResult"
        @callPopupHiddenEvent="onPopupHidden"
        @callGridRefresh="gridRefresh"
      />
    </slot>
  </div>  
 
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxButton,
  DxScrolling,
  DxColumnChooser
} from "devextreme-vue/data-grid";
import CoreService from "@/services/core.service";
import { ref, toRef } from "@vue/reactivity";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxButton,
  },
  props: {
    pGridId: {
      type: Number,
      required: true,
    },
    pGridFilter: null,
    buttonItem: { type: Object },
    rowItem: { type: Object },
    masterItem: { type: Object },
    masterRowItem: { type: Object },
  },
  setup(props, { emit }) { 

    const guid = ref(CoreService.getRandomGuid());
    const dataGridId = parseInt(props.pGridId);
    const gridElement = ref();
    const gridItem = ref({});  
    const filterValue = toRef(() => props.pGridFilter);    

    const isLoaded = ref(false);
    const columns = ref([]);
    const columnButtons = ref([]);
    const toolbarButtons = ref([]);
    const columnChooserEnabled = ref(false);
    const popupGridButtonId = ref();
    const popupDataItem = ref();
    const isDropDownPopupEnable = ref(false);

    const dataSource = ref();
    const dataGridFormResult = ref([]);

    //lookup sql where parametresi almak için url querystring ile kullanılıyor
    const lookupQueryParams = "?lookupButtonFormItemId="+props.buttonItem.dyn_data_grid_button_form_item_id;


    CoreService.get("GetDataGrid/" + dataGridId).then((result) => {
      gridItem.value = result.grid[0] ?? {};
      columns.value = result.columns;
     
      if(result.parametreFormItems.length > 0){

        let headerParameters = {};

        result.parametreFormItems.forEach((item)=>{

          if(props.masterRowItem != null)
          {
            headerParameters[item.parameter_name] = props.masterRowItem[item.parameter_name];
          }

          if(props.rowItem != null)
          {
            headerParameters[item.parameter_name] = props.rowItem[item.parameter_name];
          }

        });      
        dataSource.value = CoreService.customStore(null,"GetList/" + dataGridId+lookupQueryParams,headerParameters);
      }
      else{
        dataSource.value = CoreService.customStore(null,"GetList/" + dataGridId+lookupQueryParams);
      }


      const toolbarButtonData = result.buttons.filter(
        (d) => d.is_column_button == false
      );
      const columnButtonData = result.buttons.filter(
        (d) => d.is_column_button == true && d.lookup_visible == true
      );

      if (toolbarButtonData) {
        toolbarButtons.value = toolbarButtonData;
      }

      if (columnButtonData) {
        columnButtons.value = columnButtonData;
      }

      columnChooserEnabled.value = result.columnChooserEnabled;

      isLoaded.value = true;     

    });

    function onToolbarPreparing(e) {

      // e.toolbarOptions.items.unshift({
      //   location: "after",
      //   widget: "dxButton",
      //   options: {
      //     icon: "refresh",
      //     hint: "Yeniden Yükle",
      //     onClick: function () {
      //       e.component.refresh();
      //     },
      //   },
      // });
    
      toolbarButtons.value.forEach(function (item) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: item.icon,
            hint: item.caption,
            visible:item.lookup_visible,
            onClick: function () {              
              CoreService.get(
                "GetDataGridForm/" + item.dyn_data_grid_button_id
              ).then((result) => {
                dataGridFormResult.value = result;
                popupGridButtonId.value = item.dyn_data_grid_button_id;
                popupDataItem.value = null;
                isDropDownPopupEnable.value = true;
               
              });
            },
          },
        });
      });
    }

    function gridRefresh() {
      gridElement.value.instance.refresh();
    }

    function onPopupHidden() {
      popupGridButtonId.value = null;
      popupDataItem.value = null;
      isDropDownPopupEnable.value = false;
    }

    function onButtonClick(e, id) {
      CoreService.get("GetDataGridForm/" + id).then((result) => {
        dataGridFormResult.value = result;
        popupGridButtonId.value = id;
        popupDataItem.value = e.row.data;

        isDropDownPopupEnable.value = true;
      });
    }

    function onRowClick(e) {
      emit("callGridRowClick", e);
    }

    return {
      guid,
      dataGridId,
      gridElement,
      filterValue,
      isLoaded,
      gridItem,
      columns,
      columnButtons,
      toolbarButtons,
      dataSource,
      popupGridButtonId,
      popupDataItem,
      isDropDownPopupEnable,
      columnChooserEnabled,
      onToolbarPreparing,
      onRowClick,
      onButtonClick,
      onPopupHidden,
      gridRefresh,
      dataGridFormResult,
    };
  },
};
</script>
