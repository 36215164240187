<template :key="guid">
  <DxDropDownBox
    ref="dropDownElement"
    :key="'ddb_' + buttonItem.parameter_name + '_' + guid"
    v-model:opened="dropDownOpened"
    :disabled="
      (dropDownIsDisabled && !isNullFilterValue) || dropDownIsDisabledRule
    "
    placeholder="..."
    :deferRendering="buttonItem.lookup_defer_rendering"
    fieldTemplate="field"
    contentTemplate="content"
  >
    <template #field>
      <DxTagBox
        ref="tagBoxElement"
        :key="'tb_' + buttonItem.parameter_name + '_' + guid"
        :displayExpr="buttonItem.lookup_display_field"
        :valueExpr="buttonItem.lookup_value_field"
        @valueChanged="tagBoxValueChanged"
        placeholder=""
        :openOnFieldClick="false"
      />
      <DxTextBox v-if="false" />
    </template>

    <template #content>
      <DropDownGridComponent
        ref="gridElement"
        :key="'ddg_' + buttonItem.parameter_name + '_' + guid"
        @callGridRowClick="onGridRowClick"
        :buttonItem="buttonItem"
        :pGridId="gridElementId"
        :pGridFilter="filterValue"
        :rowItem="dataItem"
        :masterItem="masterRelationItem"
        :masterRowItem="masterRelationRowItem"
      />
    </template>
  </DxDropDownBox>
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { watch } from "vue";
import DxDropDownBox from "devextreme-vue/drop-down-box";

import DxTextBox from "devextreme-vue/text-box";
import DxTagBox from "devextreme-vue/tag-box";
import CoreService from "@/services/core.service";
import notify from "devextreme/ui/notify";

export default {
  components: {
    DxDropDownBox,
    DxTextBox,
    DxTagBox,
  },

  props: {
    buttonFormItem: { type: Object },
    buttonFormItemData: { type: Object },
    formDataItem: { type: Object },
    masterItem: { type: Object },
    masterRowItem: { type: Object },
  },

  setup(props) {
    const masterRelationItem = reactive(props.masterItem);
    const masterRelationRowItem = reactive(props.masterRowItem);
    const guid = ref(CoreService.getRandomGuid());
    var rowData = [];
    var rowDataValue = [];
    var isTagboxValueManuelChanged = false;
    const filterValue = ref();
    let filterList = [null, null, null];
    const gridElement = ref();
    const dropDownElement = ref();
    const tagBoxElement = ref();
    let data = reactive(props.formDataItem);
    const gridElementId = ref(props.buttonFormItem.lookup_dyn_data_grid_id);
    const dropDownIsDisabled = ref(false);
    const dropDownIsDisabledRule = ref(false);
    const dropDownOpened = ref(false);
    const buttonItem = reactive(props.buttonFormItem);
    const dataGridElement = ref();

    //eğer bağlı olduğu lookup nesnesinin değeri null ise dropDownIsDisabled geçersiz yapar ve filtrelemeyi null olarak yapar
    const isNullFilterValue = buttonItem.cascade_null_value_filter;

    //edit modda verileri bindirmek için kullanılır
    if (data[buttonItem.parameter_name] != null) {
      let headerParameters = {};
      headerParameters[buttonItem.lookup_value_field] =
        data[buttonItem.parameter_name];

      //cascade aktif ise filtrelemeye ekleniyor
      const cascadeItem = props.buttonFormItemData.find(
        (d) =>
          d.dyn_data_grid_button_form_item_id == buttonItem.cascade_form_item_id
      );

      if (cascadeItem != null) { 
        if (data[cascadeItem.parameter_name] == null && isNullFilterValue) {          
          filterList[0] = [buttonItem.cascade_filter_field,"isblank",null];
        } else {
          
          filterList[0] = [buttonItem.cascade_filter_field,"=",data[cascadeItem.parameter_name]];
        }
        headerParameters[buttonItem.cascade_filter_field] = data[cascadeItem.parameter_name];
      }

      const cascade2Item = props.buttonFormItemData.find(
        (d) =>
          d.dyn_data_grid_button_form_item_id ==
          buttonItem.cascade2_form_item_id
      );
      if (cascade2Item != null) {
        
        if (data[cascade2Item.parameter_name] == null && isNullFilterValue) {          
          filterList[1] = [buttonItem.cascade2_filter_field,"isblank",null];
        } else {
          
          filterList[1] = [buttonItem.cascade2_filter_field,"=",data[cascade2Item.parameter_name]];
        }
        headerParameters[buttonItem.cascade2_filter_field] =
          data[cascade2Item.parameter_name];
      }

      const cascade3Item = props.buttonFormItemData.find(
        (d) =>
          d.dyn_data_grid_button_form_item_id ==
          buttonItem.cascade3_form_item_id
      );
      if (cascade3Item != null) {       

        if (data[cascade3Item.parameter_name] == null && isNullFilterValue) {          
          filterList[2] = [buttonItem.cascade3_filter_field,"isblank",null];
        } else {
          
          filterList[2] = [buttonItem.cascade3_filter_field,"=",data[cascade3Item.parameter_name]];
        }

        headerParameters[buttonItem.cascade3_filter_field] =
          data[cascade3Item.parameter_name];
      }
      updateFilterValue();

      CoreService.get("GetDataGrid/" + buttonItem.lookup_dyn_data_grid_id).then(
        (result) => {
          if (result.parametreFormItems.length > 0) {
            result.parametreFormItems.forEach((item) => {
              if (props.masterRowItem != null) {
                headerParameters[item.parameter_name] =
                  props.masterRowItem[item.parameter_name];
              }
            });
          }

          CoreService.get(
            "GetLookupSelectedData/" + buttonItem.lookup_dyn_data_grid_id,
            headerParameters
          ).then((result) => {
            if (result.success) {
              rowData = result.result;
              rowDataValue = result.result.map(
                (x) => x[buttonItem.lookup_value_field]
              );
              tagBoxElement.value.instance.option("dataSource", rowData);
              isTagboxValueManuelChanged = true;
              tagBoxElement.value.instance.option("value", rowDataValue);
            } else {
              notify(result.message, "error");
            }
          });
        }
      );
    }
    //INSERT EKRANINDA ve NULL VALUE FİLTRELEME AKTİF İSE
    else if(isNullFilterValue){

      //cascade aktif ise filtrelemeye ekleniyor
      const cascadeItem = props.buttonFormItemData.find((d) =>d.dyn_data_grid_button_form_item_id == buttonItem.cascade_form_item_id);

      if (cascadeItem != null) { 
        if (data[cascadeItem.parameter_name] == null) {          
          filterList[0] = [buttonItem.cascade_filter_field,"isblank",null];
        } else {
          
          filterList[0] = [buttonItem.cascade_filter_field,"=",data[cascadeItem.parameter_name]];
        }
        
      }

      const cascade2Item = props.buttonFormItemData.find(
        (d) =>
          d.dyn_data_grid_button_form_item_id ==
          buttonItem.cascade2_form_item_id
      );
      if (cascade2Item != null) {
        if (data[cascade2Item.parameter_name] == null) {          
          filterList[1] = [buttonItem.cascade2_filter_field,"isblank",null];
        } else {
          
          filterList[1] = [buttonItem.cascade2_filter_field,"=",data[cascade2Item.parameter_name]];
        }    
      }

      const cascade3Item = props.buttonFormItemData.find((d) =>d.dyn_data_grid_button_form_item_id ==buttonItem.cascade3_form_item_id);
      if (cascade3Item != null) {        
        if (data[cascade3Item.parameter_name] == null) {          
          filterList[2] = [buttonItem.cascade3_filter_field,"isblank",null];
        } else {
          
          filterList[2] = [buttonItem.cascade3_filter_field,"=",data[cascade3Item.parameter_name]];
        }  
      }
      updateFilterValue();

    }
   

    if (buttonItem.cascade_form_item_id != null) {
      dropDownIsDisabled.value = true;
      cascadeWatch(
        0,
        buttonItem.cascade_form_item_id,
        buttonItem.cascade_filter_field
      );
    }

    if (buttonItem.cascade2_form_item_id != null) {
      dropDownIsDisabled.value = true;
      cascadeWatch(
        1,
        buttonItem.cascade2_form_item_id,
        buttonItem.cascade2_filter_field
      );
    }

    if (buttonItem.cascade3_form_item_id != null) {
      dropDownIsDisabled.value = true;
      cascadeWatch(
        2,
        buttonItem.cascade3_form_item_id,
        buttonItem.cascade3_filter_field
      );
    }

    function onGridRowClick(e) {
      const rowDataItem = e.data;
      const rowDataItemKeyValue = rowDataItem[buttonItem.lookup_value_field];

      if (buttonItem.lookup_multiple_selection) {
        //nulluk kontrolü
        if (rowDataValue == null) rowDataValue = [];

        //seçili olan satır daha önce listede yok ise listeye eklenir
        if (!rowDataValue.includes(rowDataItemKeyValue)) {
          rowDataValue.push(rowDataItemKeyValue);
          rowData.push(rowDataItem);
        }

        //çoklu seçimde açık olan dropdown penceresi kapatılmaz
        //pencere manuel kapatılması lazım
        dropDownOpened.value = true;
      } else {
        rowData = [rowDataItem];
        rowDataValue = [rowDataItemKeyValue];

        //tekli seçim ise dropdown penceresi otomatik kapatılır
        dropDownOpened.value = false;
      }

      tagBoxElement.value.instance.option("dataSource", rowData);
      isTagboxValueManuelChanged = true;
      tagBoxElement.value.instance.option("value", rowDataValue);
    }

    function tagBoxValueChanged(e) {
      if (isTagboxValueManuelChanged) isTagboxValueManuelChanged = false;
      else {
        rowDataValue = e.value;
      }

      //farklı bir nesne için valueRule seçilmiş ise lookup içerisinden value(key) kolonu haricinde bir colon değerine erişmek için kullanılır
      //örneğin kurum id değerine değil de kurum unvan değerine erişmek gibi
      data[buttonItem.parameter_name+"_lookup_data"] = rowData.length == 0 ? {} : rowData[0];
      if (buttonItem.lookup_multiple_selection) {
        data[buttonItem.parameter_name] = e.value;        
      } else {
        data[buttonItem.parameter_name] = e.value[0];
      }
    }

    function cascadeWatch(cascadeIndex, cascadeFormItemId, cascadeFilterField) {
      const cascadeItem = props.buttonFormItemData.find(
        (d) => d.dyn_data_grid_button_form_item_id == cascadeFormItemId
      );

      const parameterName = cascadeItem.parameter_name;

      //filtrelenecek bir değer var ise
      //filtreye eklenir
      if (data[parameterName] != null) {
        filterList[cascadeIndex] = [cascadeFilterField,"=",data[parameterName]];

        // if (data[cascadeItem.parameter_name] == null && isNullFilterValue) {          
        //   filterList[0] = [buttonItem.cascade_filter_field,"isblank",null];
        // } else {
          
        //   filterList[0] = [buttonItem.cascade_filter_field,"=",data[cascadeItem.parameter_name]];
        // }
        

        updateFilterValue();
      }

      watch(
        () => data[parameterName],
        (newValue) => {         

          if (newValue == null && !isNullFilterValue) {
            {
              filterList[cascadeIndex] = null;
            }
          } else {
            if (isNullFilterValue && newValue == null) {
              filterList[cascadeIndex] = [cascadeFilterField,"isblank",null];
            } else {
              filterList[cascadeIndex] = [cascadeFilterField, "=", newValue];
            }
          }
          updateFilterValue();
          tagBoxElement.value.instance.reset();
          tagBoxElement.value.instance.option("dataSource", null);
        },
        { deep: true }
      );
    }

    function updateFilterValue() {
      dropDownIsDisabled.value = true;

      //["master_detail_enabled","=",false],"and",["export_enabled","=",false]
      let filter = [];
      const filterItems = filterList.filter((d) => d != null);
      const cascadeCount =
        (buttonItem.cascade_form_item_id != null ? 1 : 0) +
        (buttonItem.cascade2_form_item_id != null ? 1 : 0) +
        (buttonItem.cascade3_form_item_id != null ? 1 : 0);

      const maxIndex = filterItems.length - 1;

      filterItems.forEach((item, index) => {
        filter.push(item);
        if (index < maxIndex) {
          filter.push("and");
        }
      });     
      filterValue.value = filter;
      dropDownIsDisabled.value = filterItems.length != cascadeCount;
    }    

    //disabled rule function var ise
    if (buttonItem.disable_rule_form_item_names?.length > 0) {
      try {
        dropDownIsDisabledRule.value = eval(
          buttonItem.disable_rule_js_function
        );
        buttonItem.disable_rule_form_item_names.forEach(
          (watchItemParameterName) => {
            watch(
              () => data[watchItemParameterName],
              () => {
                dropDownIsDisabledRule.value = eval(
                  buttonItem.disable_rule_js_function
                );
                //eğer kontrol sonucu false ise nesneye default değeri atanır
                if (eval(buttonItem.disable_rule_js_function)) {
                  if (buttonItem.dx_data_type == "boolean") {
                    data[buttonItem.parameter_name] =
                      buttonItem.default_value == "true";
                  } else if (buttonItem.dx_data_type == "number") {
                    data[buttonItem.parameter_name] =
                      buttonItem.default_value == null
                        ? null
                        : parseFloat(buttonItem.default_value);
                  } else {
                    data[buttonItem.parameter_name] = buttonItem.default_value;
                  }

                  tagBoxElement.value.instance.reset();
                  tagBoxElement.value.instance.option("dataSource", null);
                }
              }
            );
          }
        );
      } catch (error) {
        console.log(error);
      }
    }

    return {
      guid,
      masterRelationItem,
      masterRelationRowItem,
      dropDownElement,
      tagBoxElement,
      isNullFilterValue,
      dropDownIsDisabled,
      dropDownIsDisabledRule,
      dropDownOpened,
      dataGridElement,
      gridElementId,
      gridElement,
      buttonItem,
      filterValue,
      onGridRowClick,
      tagBoxValueChanged,
    };
  },
};
</script>
