<template>
  <p>Değişken eklemek için;</p>
  <ul>    
    <li>Türkçe karakter ve boşluk kullanmadan değişken adını yazınız.</li>
    <li>Örnekler; <b>{DOCVARIABLE "evrak_no"}</b> <b>{DOCVARIABLE "oda_sicil_no"}</b> <b>{DOCVARIABLE "firma_unvani"}</b> </li>    
  </ul>
  <button @click="createEmptyDocVariableField()">Değişken Ekle</button>
  <button @click="createEmptyDocVariableFieldEvrakNo()">Evrak No Ekle</button>
  <button @click="showAllFieldCodes()">Değişkenleri Göster</button>
  <button @click="showAllFieldResults()">Değişkenleri Gizle</button>
  <div ref="richEditElement"></div>
</template>

<script>
import {
  create,
  createOptions,
  ViewType,
  RichEditUnit,
} from "devexpress-richedit";

import { ref,reactive, onMounted   } from "vue";
import { DocumentFormat } from 'devexpress-richedit/lib/core/document-format';


export default {
  props: {
  pItem: { type: Object },
  pFormData: { type: Object },  
},
  components: {},

  setup(props) {

    const item = reactive(props.pItem);
    const data = reactive(props.pFormData);

    const richEditElement = ref();
    var richEdit = null;

    onMounted(() => {     

      const options = createOptions();
      options.unit = RichEditUnit.Centimeter;
      options.view.viewType = ViewType.PrintLayout;

      options.readOnly = false;
      options.width = "100vh";
      options.height = "100vh";

      // options.events.calculateDocumentVariable = (s,e) => {       
      //   e.value = "aha evrak";
      //  };

       options.events.documentChanged = function() {                
        richEdit.exportToBase64(function(base64){
          data[item.parameter_name] = base64;
        })
        };

      richEdit = create(richEditElement.value, options);

      if(data[item.parameter_name])
      {
        richEdit.openDocument(data[item.parameter_name], '', DocumentFormat.OpenXml);
      }

      setTimeout(() => {
        richEdit.document.fields.showAllFieldCodes();   
      }, 500);
      
     
    });

    function createEmptyDocVariableField(){      
      eval("richEdit.executeCommand(DevExpress.RichEdit.MailMergeTabItemId.CreateEmptyDocVariableField);");      
    }

    function createEmptyDocVariableFieldEvrakNo(){      
      richEdit.selection.activeSubDocument.fields.create(richEdit.selection.active, 'DOCVARIABLE "evrak_no"');      
    }

    function showAllFieldCodes(){    
      richEdit.document.fields.showAllFieldCodes();         
    }

    function showAllFieldResults(){  
      richEdit.document.fields.showAllFieldResults();             
    }

    return {
      richEditElement,
      createEmptyDocVariableField,
      createEmptyDocVariableFieldEvrakNo,
      showAllFieldCodes,
      showAllFieldResults,     
    };
  },


 
};
</script>