<template :key="guid">
<DxForm ref="formElement" :formData="data" :colCount="button.col_count">
  <DxGroupItem
    v-for="g in groups"
    :key="g"
    :caption="g.caption"
    :colCount="g.col_count"
    :colSpan="g.col_span"
    :visibleIndex="g.visible_index"    
  > 
    <DxEmptyItem 
    v-for="item in items.filter((i) => i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id && i.dx_editor_type == 'dxEmptyItem')"
    :key="'empty_item' + item.dyn_data_grid_button_form_id"    
    :visibleIndex="item.visible_index"
    :colSpan="item.col_span"
    />

    <DxItem 
    v-for="item in items.filter((i) => i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id && i.dx_editor_type == 'dynHtmlView')"
    :key="'dyn_html_view_' + item.parameter_name"  
    :dataField="item.parameter_name"  
    :visibleIndex="item.visible_index"
    :label="{
        text: item.label_text,
        visible: item.label_visible,
      }" 
    :colSpan="item.col_span"   
    >
    <template #default>     
      <div v-html="data[item.parameter_name]"></div>      
    </template>
    
    </DxItem>
        
    <DxItem 
      v-for="item in items.filter((i) => i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id && !i.is_lookup && i.dx_editor_type != 'dynHtmlView' && i.dx_editor_type != 'dxEmptyItem' && i.dx_editor_type != 'dxFileUploader' && i.dx_editor_type != 'dynMatbuForm' && i.dx_editor_type != 'dynMatbuFormSablon')"
      :key="'normal_input_' + item.parameter_name"
      :dataField="item.parameter_name"
      :visible="item.visible"
      :visibleIndex="item.visible_index"
      :label="{
        text: item.label_text,
        visible: item.dx_editor_type != 'dxCheckBox' && item.label_visible,
      }"     
      :isRequired="item.is_required && item.dx_data_type !== 'boolean'"
      :helpText="getLookupFieldHelpText(item)"
      :colSpan="item.col_span"
  >
    <PopupFormItemComponent :pItem="item" :pFormData="data" :pFields="items" />
  </DxItem>
    
    <DxItem 
      v-for="item in items.filter((i) => i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id && i.is_lookup)"
      :key="'normal_lookup_' + item.parameter_name"
      :dataField="item.parameter_name"
      :visible="item.visible"
      :visibleIndex="item.visible_index"
      :label="{
        text: item.label_text,
        visible: item.label_visible,
      }"
      :isRequired="item.is_required"
      :helpText="getLookupFieldHelpText(item)"
      :colSpan="item.col_span"
    >
      <DropDownPopupComponent
        :key="'popupDdb_' + item.parameter_name"
        :buttonFormItem="item"
        :buttonFormItemData="items"
        :formDataItem="data"
        :masterItem="masterRelationItem"
        :masterRowItem="masterRelationRowItem"
      />
    </DxItem>

    <DxItem
      v-for="item in items.filter((i) => i.dyn_data_grid_button_form_id == g.dyn_data_grid_button_form_id && isCsvImport && i.dx_editor_type == 'dxFileUploader')"
      :key="'normal_csv_' + item.parameter_name"
      :dataField="item.parameter_name"
      :visible="item.visible"
      :visibleIndex="item.visible_index"
      :label="{
        text: item.label_text,
        visible: item.label_visible,
      }"
      :isRequired="item.is_required"
      :helpText="item.help_text"
      :colSpan="item.col_span"
    >
      <CsvFileUploaderPopupComponent
        :key="'popupFileUploader_' + item.parameter_name"
        :buttonFormItem="item"
        :buttonFormItemData="items"
        :formDataItem="data"
      />
    </DxItem>

   
  </DxGroupItem>
</DxForm>
</template>

<script>
import { DxForm, DxGroupItem,DxItem ,DxEmptyItem  } from "devextreme-vue/form";
import CoreService from "@/services/core.service";
import { ref, reactive, watch, onMounted } from "vue";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxItem,
    DxEmptyItem 
  },
  props: {
    pageId: Number,
    gridButtonId: Number,
    gridElement: Object,
    popupDataItem: {
      type: Object,
      default: () => {},
    },
    pGroups:Object,
    dataGridFormResult: {
      type: Object,
      default: () => {},
    },
    masterItem: {
      type: Object,
      default: () => {
        return { isEnable: false, fieldName: null, fieldValue: null };
      },
    },
    masterRowItem: Object,
    pFormElement:Object
  },

  setup(props) {
    
    const guid = ref(CoreService.getRandomGuid());
    const loadPanelVisible = ref(false);
    const tabPageId = ref(props.pageId);
    const elementId = ref(props.gridButtonId);
    const formElement = ref();
    const masterRelationItem = reactive(props.masterItem);
    const masterRelationRowItem = reactive(props.masterRowItem);
    //data değişken adı değiştirilmesin, visibleRule içinde kullanılıyor
    let data = reactive(props.popupDataItem);
    
    //const formElement = ref(props.pFormElement);
    const button = reactive(props.dataGridFormResult.button[0]);

    
    const groups = reactive(props.pGroups);
    const items = reactive(props.dataGridFormResult.items);

    const isFileUpload = button.dyn_data_grid_button_operasyon_tip_id == 6;
    const isCsvImport = button.dyn_data_grid_button_operasyon_tip_id == 5;
    const isProcedure = button.dyn_data_grid_button_operasyon_tip_id == 4;
    const isMatbuForm = button.dyn_data_grid_button_operasyon_tip_id == 13;   

    //BU BLOK grid-popup-form.vue NESNESİ İÇİNDE DE VAR, GEREKSİZ OLABİLİR KOLNTROL EDİLECEK - 25.01.2024 YUSUF
    if (data == null) {
      let jsonData = {};
      for (let item of items) {
        if (item.default_value == null) {
          jsonData[item.parameter_name] = null;
        } else {
          switch (item.dx_data_type) {
            case "boolean":
              jsonData[item.parameter_name] = item.default_value === "true";
              break;
            case "number":
              jsonData[item.parameter_name] = parseFloat(item.default_value);
              break;
            case "string":
            case "date":
            case "datetime":
            case "time":
              jsonData[item.parameter_name] = item.default_value;
              break;
            default:
              jsonData[item.parameter_name] = item.default_value;
          }
        }
      }

      //master grid kolonlarından alınması gereken değerler
      //bulunup atama yapılıyor
      const masterValueItems = items.filter(
        (d) => d.set_value_from_master == true
      );
      masterValueItems.forEach((valueItem) => {
        if (masterRelationRowItem[valueItem.parameter_name] != null) {
          jsonData[valueItem.parameter_name] =
            masterRelationRowItem[valueItem.parameter_name];
        }
      });

      //insert işleminde ilişkili üst tablonun key değerinin ataması yapıyor
      if (masterRelationItem.isEnable) {
        jsonData[masterRelationItem.fieldName] = masterRelationItem.fieldValue;
      }

      data = reactive(jsonData);
    }
    

    if (isProcedure || isMatbuForm) {
      for (let item of items) {
        if (data[item.parameter_name] != null) continue;

        if (item.default_value !== null) {
          switch (item.dx_data_type) {
            case "boolean":
              data[item.parameter_name] = item.default_value === "true";
              break;
            case "number":
              data[item.parameter_name] = parseFloat(item.default_value);
              break;
            case "string":
            case "date":
            case "datetime":
            case "time":
              data[item.parameter_name] = item.default_value;
              break;
            default:
              data[item.parameter_name] = item.default_value;
          }
        }
      }
    }

    function getLookupFieldHelpText(item) {
      let text = "";

      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade_form_item_id
          ).label_text +
          " ] ";
      }
      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade2_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade2_form_item_id
          ).label_text +
          " ] ";
      }
      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade3_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade3_form_item_id
          ).label_text +
          " ] ";
      }

      if (item.disable_rule_form_item_names?.length > 0) {
        item.disable_rule_form_item_names.forEach((ruleItemName) => {
          const ruleItem = items.find((d) => d.parameter_name == ruleItemName);
          if (ruleItem != null) text += " [ " + ruleItem.label_text + " ] ";
        });
      }

      if (text == "") return item.help_text ?? "";

      return text + "girilmelidir. " + (item.help_text ?? "");
    }

    let visibleRuleItem = {};
    onMounted(() => {
      //const formInstance = formElement.value.instance;

      items
        .filter(
          (d) =>
            d.visible &&
            d.visible_rule_form_item_names != null &&
            d.visible_rule_form_item_names?.length > 0
        )
        .forEach((item) => {
          try {
            //ilk durum atanıyor
            const evalFirstResult = eval(item.visible_rule_js_function);            
            
           
            // formInstance.itemOption(
            //   item.parameter_name,
            //   "visible",
            //   evalFirstResult
            // );
            visibleRuleItem[item.parameter_name] = evalFirstResult;
            item.visible_rule_form_item_names.forEach(
              (watchItemParameterName) => {
                watch(
                  () => data[watchItemParameterName],
                  () => {
                    const evalResult = eval(item.visible_rule_js_function);
                    // formInstance.itemOption(
                    //   item.parameter_name,
                    //   "visible",
                    //   evalResult
                    // );
                    visibleRuleItem[item.parameter_name] = evalResult;
                    //eğer kontrol sonucu false ise nesneye default değeri atanır
                    if (eval(item.visible_rule_js_function)) {
                      if (item.dx_data_type == "boolean") {
                        data[item.parameter_name] =
                          item.default_value == "true";
                      } else if (item.dx_data_type == "number") {
                        data[item.parameter_name] =
                          item.default_value == null
                            ? null
                            : parseFloat(item.default_value);
                      } else {
                        data[item.parameter_name] = item.default_value;
                      }
                    }
                  }
                );
              }
            );
          } catch (error) {
            console.log(error);
          }
        });
    });
  

    return {
      elementId,
      guid,
      formElement,
      tabPageId,
      button,
      loadPanelVisible,
      groups,
      items,            
      data,
      masterRelationItem,
      masterRelationRowItem,
      isFileUpload,
      isCsvImport,
      getLookupFieldHelpText,
    };
  },
};
</script>
