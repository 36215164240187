<template :key="guid">
  <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding:1px 1px 45px 1px;">
    <DxDashboardControl
      ref="dashboardControl"
      style="height: 100%;width: 100%;"
      workingMode="ViewerOnly"
      :dashboardId="dashboardId"
      :endpoint="endPoint"
      :fetchRemoteService="{ headers: { token: token } }"      
    />    
    </div>
</template>

<script>
import { ref } from "vue";
import { DxDashboardControl } from "devexpress-dashboard-vue";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxDashboardControl,
  },
  props: {
    id:Number
  },
  setup(props) {
    const dashboardId = ref(props.id);
    const dashboardControl = ref();
    const endPoint = CoreService.getApiUrl() + "dashboard";
    const token = CoreService.getToken();
    const guid = ref(CoreService.getRandomGuid());

    return {
      dashboardId,
      guid,
      dashboardControl,
      endPoint,
      token     
    };
  },
};
</script>
