<template :key="guid">
  <div class="dyn-tabpanel">
    <div v-html="detailTabBilgi"></div>
    <DxTabPanel
      ref="refTabPanel"
      :swipeEnabled="false"
      :showNavButtons="!isDikeyTab"
      :animationEnabled="true"
    >
      <DxItem
        v-for="item in tabPages"
        :key="item.template_name + item.id"
        :title="item.title"
        :template="getTemplateName(item.template_name, item.id)"
        :disabled="getTabPageDisabled(item)"
      />

      <template
        v-for="item in htmlViews"
        :key="'dyn_html_view_item_template_' + item.dyn_data_grid_button_id"
        v-slot:[getTemplateName(`htmlview`,item.dyn_data_grid_button_id)]
      >
        <div v-html="item.html_view_value" style="padding: 10px"></div>
      </template>

      <template
        v-for="item in forms"
        :key="'dx_form_item_template_' + item.dyn_data_grid_button_id"
        v-slot:[getTemplateName(`form`,item.dyn_data_grid_button_id)]
      >
        <GridDetayFormComponent
          :key="'dx_form_item_grid_page_' + item.dyn_data_grid_button_id"
          :pMasterItem="getMasterItemForForm(item)"
          :pGridButtonId="item.dyn_data_grid_button_id"
          :pageId="tabPageId"
        />
      </template>

      <template
        v-for="item in grids"
        :key="'dx_grid_item_template_' + item.dyn_data_grid_detail_id"
        v-slot:[getTemplateName(`grid`,item.dyn_data_grid_detail_id)]
      >
        <GridComponent
          :key="'dx_grid_item_grid_page_' + item.dyn_data_grid_detail_id"
          :elementId="item.detail_data_grid_id"
          :pageId="tabPageId"
          :isDetailGrid="true"
          :filter="getFilterValue(item)"
          :masterItem="getMasterItemForGrid(item)"
          :masterRowItem="masterRelationRowItem"
        />
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import CoreService from "@/services/core.service";
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";

export default {
  components: {
    DxTabPanel,
    DxItem,
  },
  props: {
    pageId: Number,
    masterElementId: {
      type: Number,
      required: true,
    },
    masterKeyValue: Object,
    masterRowItem: {
      type: Object,
      default: () => ({}),
    },
    isDikeyTab: Boolean,
  },
  setup(props) {
    const refTabPanel = ref();
    const masterRelationRowItem = ref(props.masterRowItem);
    //data değişkeni getTabPageDisabled fonksiyonu içinde tab page disabled rule function özelliği için kullanılıyor, [data] değişken adını değiştirme!
    const data = reactive(props.masterRowItem);

    const guid = ref(CoreService.getRandomGuid());
    const tabPageId = ref(props.pageId);
    const isLoaded = ref(false);

    const tabPages = ref([]);
    const grids = ref([]);
    const forms = ref([]);
    const htmlViews = ref([]);
    const detailTabBilgi = ref(null);
    //const isDikeyTabPage = ref(props.isDikeyTab);

    let gridDetailPrms = "/" + props.masterElementId;
    if (props.masterKeyValue != null)
      gridDetailPrms += "/" + props.masterKeyValue;
    CoreService.get("GetDataGridDetail" + gridDetailPrms).then((result) => {
      result.gridDetay.forEach(function (tabItem) {
        if (
          tabItem.visible_rule_js_function == null ||
          tabItem.visible_rule_js_function == "" ||
          eval(tabItem.visible_rule_js_function)
        ) {
          grids.value.push(tabItem);

          tabPages.value.push({
            template_name: "grid",
            id: tabItem.dyn_data_grid_detail_id,
            title: tabItem.tab_title,
            index: tabItem.tab_index,
            disable_rule_js_function: tabItem.disable_rule_js_function,
          });
        }
      });

      //form tipinde gösterim
      forms.value = result.formDetay;
      result.formDetay.forEach(function (detayItem) {
        tabPages.value.push({
          template_name: "form",
          id: detayItem.dyn_data_grid_button_id,
          title: detayItem.tab_title,
          index: detayItem.tab_index,
          disable_rule_js_function: detayItem.disable_rule_js_function,
        });
      });

      //html views gösterim
      htmlViews.value = result.htmlViews;
      result.htmlViews.forEach(function (htmlItem) {
        tabPages.value.push({
          template_name: "htmlview",
          id: htmlItem.dyn_data_grid_button_id,
          title: htmlItem.tab_title,
          index: htmlItem.tab_index,
          disable_rule_js_function: htmlItem.disable_rule_js_function,
        });
      });

      detailTabBilgi.value = result.detailTabBilgi;

      //tabPages dizisi index'e göre yeniden sıralanıyor
      tabPages.value.sort((a, b) => a.index - b.index);

      isLoaded.value = true;
    });

    function getTemplateName(tip, id) {
      return "tabItemTemplate_" + tip + id;
    }

    function getTabPageDisabled(item) {
      if (
        item.disable_rule_js_function == null ||
        item.disable_rule_js_function == ""
      ) {
        return false;
      }
      return eval(item.disable_rule_js_function);
    }

    function getMasterItemForForm(item) {
      const masterKeyFieldName = item["master_data_grid_key_field"];
      const masterKeyValue = props.masterRowItem[masterKeyFieldName];
      return {
        gridId: props.masterElementId,
        fieldName: masterKeyFieldName,
        fieldValue: masterKeyValue,
      };
    }

    function getFilterValue(item) {
      const masterKeyFieldName = item["master_data_grid_key_field"];
      const detailKeyFieldName = item["detail_data_grid_key_field"];
      const masterKeyValue = props.masterRowItem[masterKeyFieldName];
      let jsonData = {};
      jsonData[detailKeyFieldName] = masterKeyValue;
      return jsonData;
    }
    // insert işleminder detail gride master gridin ilgili değerini gönderir
    function getMasterItemForGrid(item) {
      const masterKeyFieldName = item["master_data_grid_key_field"];
      const masterKeyValue = props.masterRowItem[masterKeyFieldName];
      const detailKeyFieldName = item["detail_data_grid_key_field"];
      return {
        isEnable: true,
        fieldName: detailKeyFieldName,
        fieldValue: masterKeyValue,
      };
    }

    onMounted(() => {
      if (props.isDikeyTab) {
        try {
          refTabPanel.value.instance._$element[0].classList.add("dikey-multiview");
          refTabPanel.value.instance._$tabContainer[0].classList.add("dikey-tabpanel-tabs");
          refTabPanel.value.instance._tabs._$wrapper[0].classList.add("dikey-tabs-wrapper");
        } catch (error) {
          console.log("dikey tab hatası:", error);
        }
      }
    });

    return {
      refTabPanel,
      guid,
      tabPageId,
      tabPages,      
      forms,
      htmlViews,
      grids,
      isLoaded,
      getTemplateName,
      getMasterItemForForm,
      getMasterItemForGrid,
      getFilterValue,
      getTabPageDisabled,
      masterRelationRowItem,
      data,
      detailTabBilgi,
    };
  },
};
</script>

<style>
div.dyn-tabpanel {
  border: 1px solid #b7b7b7;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  padding-left: 5px;
}

div.dikey-multiview {
  display: flex !important;
  flex-direction: row-reverse !important;
}

div.dikey-tabpanel-tabs {
  width: auto !important;
  margin: 0 5px 5px 5px !important;
}

div.dikey-tabs-wrapper {
  display: flex !important;
  flex-direction: column !important;
  height: auto !important;
}
</style>
