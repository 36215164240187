<template :key="guid">
  <DxLoadPanel
    v-model:visible="loadPanelVisible"
    :show-indicator="true"
    :show-pane="true"
    :shading="true"
    shading-color="rgba(0,0,0,0.4)"
    :container="'#dynTabPage' + tabPageId"
  />

  <DxPopup
    ref="popupElement"
    :key="'GridPopupForm_' + guid"
    :visible="true"
    :dragEnabled="true"
    :hideOnOutsideClick="false"
    :showTitle="true"
    :title="button.caption"
    :width="button.popup_width"
    :height="button.popup_height"
    :container="isFullScreen ? '' : '#dynTabPage' + tabPageId"
    @hidden="popupOnHidden"
    :animation="popupAnimationAttribute"
    :fullScreen="isFullScreen"
  >
    <DxPosition at="center" my="center" offset="0 40" />

    <div style="position: absolute; top: 5px; right: 6px; font-size: 10px">
      <DxButton
        type="normal"
        icon="contentlayout"
        :text="elementId"
        :visible="formDesignerVisible"
        @click="onClickFormDesign"
      />
    </div>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="before"
      :options="{ text: 'KAPAT', onClick: onKapatClick }"
    />

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="after"
      :options="{ text: 'KAYDET', onClick: onKaydetClick }"
    />
    <DxScrollView showScrollbar="always">
      <div v-show="button.aciklama">
        <div v-html="button.aciklama"></div>
        <br />
      </div>

      <DxForm
        ref="formElement"
        :formData="groups.length == 0 ? null : data"
        labelMode="floating"
      >
        <!-- NORMAL GRUP NESNELER BAŞLANGIÇ-->

        <DxGroupItem
          v-if="
            refProps.dataGridFormResult.groups.filter(
              (g) => g.in_tab_page == false
            ).length > 0
          "
        >
          <PopupFormGroupComponent
            :pageId="refProps.pageId"
            :gridButtonId="refProps.gridButtonId"
            :gridElement="refProps.gridElement"
            :popupDataItem="data"
            :dataGridFormResult="refProps.dataGridFormResult"
            :masterItem="refProps.masterItem"
            :masterRowItem="refProps.masterRowItem"
            :pFormElement="formElement"
            :pGroups="
              refProps.dataGridFormResult.groups.filter(
                (g) => g.in_tab_page == false
              )
            "
          />
        </DxGroupItem>

        <!-- NORMAL GRUP NESNELER SON -->

        <DxTabbedItem
          v-for="t in tabs"
          :key="t"
          :colCount="t.col_count"
          :colSpan="t.col_span"
          :visibleIndex="t.visible_index"
        >
          <DxTabPanelOptions
            :swipeEnabled="true"
            :showNavButtons="true"
            :animationEnabled="true"
          />
          <DxTab
            v-for="p in tabPages.filter(
              (i) =>
                i.dyn_data_grid_button_form_tab_id ==
                t.dyn_data_grid_button_form_tab_id
            )"
            :key="p"
            :title="p.title"
          >
            <!-- TAB PAGE NESNELERİ BAŞLANGIÇ -->

            <DxGroupItem>
              <PopupFormGroupComponent
                :pageId="refProps.pageId"
                :gridButtonId="refProps.gridButtonId"
                :gridElement="refProps.gridElement"
                :popupDataItem="data"
                :dataGridFormResult="refProps.dataGridFormResult"
                :masterItem="refProps.masterItem"
                :masterRowItem="refProps.masterRowItem"
                :pFormElement="formElement"
                :pGroups="
                  refProps.dataGridFormResult.groups.filter(
                    (g) =>
                      g.dyn_data_grid_button_form_id ==
                      p.dyn_data_grid_button_form_id
                  )
                "
              />
            </DxGroupItem>

            <!-- TAB PAGE NESNELERİ SON -->
          </DxTab>
        </DxTabbedItem>
      </DxForm>

      <div
        v-for="f in refProps.dataGridFormResult.items.filter(
          (i) => i.dx_editor_type == 'dynMatbuFormSablon'
        )"
        :key="f"
      >
        <MatbuFormSablonComponent :pItem="f" :pFormData="data" />
      </div>

      <div v-if="isMatbuForm">
        <MatbuFormComponent
          :pButtonItem="button"
          :pFormData="data"
          :pFields="refProps.dataGridFormResult.items"
        />
      </div>

      <FileUploaderPopupComponent
        v-if="isFileUpload"
        :formDataItem="data"
        :buttonItem="button"
      />
    </DxScrollView>
  </DxPopup>

  <DxPopup
    :key="'GridFormDesignerPopup_' + guid"
    v-model:visible="isFormDesignerPopupVisible"
    :showCloseButton="true"
    :wrapperAttr="formDesignerPopupStyle"
    :title="'ELEMENT ID = ' + elementId"
    :fullScreen="true"
  >
    <div>
      <FormDesignerComponent
        v-if="isFormDesignerPopupVisible"
        tip="F"
        :formId="elementId"
      />
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup, DxToolbarItem, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import {
  DxForm,
  DxGroupItem,
  DxTabbedItem,
  DxTabPanelOptions,
  DxTab,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import CoreService from "@/services/core.service";
import { useAuthStore } from "@/services/auth.store";
import { ref, reactive, watch, onMounted } from "vue";
import notify from "devextreme/ui/notify";
import hideToasts from "devextreme/ui/toast/hide_toasts";
import { useTabPanelStore } from "@/services/tab-panel.store";

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxPosition,
    DxScrollView,
    DxForm,
    DxGroupItem,
    DxTabbedItem,
    DxTabPanelOptions,
    DxTab,
    DxButton,
    DxLoadPanel,
  },
  props: {
    pageId: Number,
    gridButtonId: Number,
    gridElement: Object,
    popupDataItem: {
      type: Object,
      default: () => {},
    },
    dataGridFormResult: {
      type: Object,
      default: () => {},
    },
    gridColumns: {
      type: Object,
      default: () => {},
    },
    masterItem: {
      type: Object,
      default: () => {
        return { isEnable: false, fieldName: null, fieldValue: null };
      },
    },
    masterRowItem: Object,
  },

  setup(props, { emit }) {
    const refProps = ref(props);

    const authStore = useAuthStore();
    const tabPanelStore = useTabPanelStore();
    const formDesignerVisible = ref(authStore.formDesignerVisible);

    const guid = ref(CoreService.getRandomGuid());
    const loadPanelVisible = ref(false);
    const tabPageId = ref(props.pageId);
    const elementId = ref(props.gridButtonId);
    const masterRelationItem = reactive(props.masterItem);
    const masterRelationRowItem = reactive(props.masterRowItem);
    //data değişken adı değiştirilmesin, visibleRule içinde kullanılıyor
    let data = reactive(props.popupDataItem);
    const popupElement = ref();
    const formElement = ref();
    const button = reactive(props.dataGridFormResult.button[0]);

    const isFullScreen = ref(button.popup_fullscreen);

    const groups = reactive(props.dataGridFormResult.groups);
    const tabs = reactive(props.dataGridFormResult.tabs);
    const tabPages = reactive(props.dataGridFormResult.tab_pages);
    const items = reactive(props.dataGridFormResult.items);
    const columns = reactive(props.gridColumns);

    //form üzerindeki tüm nesneler gizlenmiş ise ve silme operasyonu değil ise form oto kayıt yapılır
    const isOtoKaydetVeKapat = button.otomatik_tetikle;
    //items.filter((d) => d.visible == true).length == 0 && ![3,5,6,11].includes(button.dyn_data_grid_button_operasyon_tip_id);

    const isFileUpload = button.dyn_data_grid_button_operasyon_tip_id == 6;
    const isCsvImport = button.dyn_data_grid_button_operasyon_tip_id == 5;
    const isProcedure = button.dyn_data_grid_button_operasyon_tip_id == 4;
    const isGridDataImport = button.dyn_data_grid_button_operasyon_tip_id == 8;
    const isWebServiceGet = button.dyn_data_grid_button_operasyon_tip_id == 9;
    const isFileDelete = button.dyn_data_grid_button_operasyon_tip_id == 11;
    const isReportDisplay = button.dyn_data_grid_button_operasyon_tip_id == 12;
    const isMatbuForm = button.dyn_data_grid_button_operasyon_tip_id == 13;
    const isMatbuFormImzala =
      button.dyn_data_grid_button_operasyon_tip_id == 15;

    const isFormDesignerPopupVisible = ref(false);

    if (
      window.innerHeight - 104 < button.popup_height ||
      window.innerWidth < button.popup_width
    ) {
      isFullScreen.value = true;
    }

    if (data == null) {
      let jsonData = {};
      for (let item of items) {
        if (item.default_value == null) {
          jsonData[item.parameter_name] = null;
        } else {
          switch (item.dx_data_type) {
            case "boolean":
              jsonData[item.parameter_name] = item.default_value === "true";
              break;
            case "number":
              jsonData[item.parameter_name] = parseFloat(item.default_value);
              break;
            case "string":
            case "date":
            case "datetime":
            case "time":
              jsonData[item.parameter_name] = item.default_value;
              break;
            default:
              jsonData[item.parameter_name] = item.default_value;
          }
        }
      }

      //master grid kolonlarından alınması gereken değerler
      //bulunup atama yapılıyor
      const masterValueItems = items.filter(
        (d) => d.set_value_from_master == true
      );
      masterValueItems.forEach((valueItem) => {
        if (masterRelationRowItem[valueItem.parameter_name] != null) {
          jsonData[valueItem.parameter_name] =
            masterRelationRowItem[valueItem.parameter_name];
        }
      });

      //insert işleminde ilişkili üst tablonun key değerinin ataması yapıyor
      if (masterRelationItem.isEnable) {
        jsonData[masterRelationItem.fieldName] = masterRelationItem.fieldValue;
      }

      data = reactive(jsonData);
    }
    //update işlemi
    else {
      //masterdan alınacak kolonlar bulunuyor
      const masterUpdateValueItems = items.filter(
        (d) => d.set_value_from_master == true
      );
      masterUpdateValueItems.forEach((valueItem) => {
        if (masterRelationRowItem[valueItem.parameter_name] != null) {
          data[valueItem.parameter_name] =
            masterRelationRowItem[valueItem.parameter_name];
        }
      });
    }

    if (isProcedure) {
      for (let item of items) {
        if (data[item.parameter_name] != null) continue;

        if (item.default_value !== null) {
          switch (item.dx_data_type) {
            case "boolean":
              data[item.parameter_name] = item.default_value === "true";
              break;
            case "number":
              data[item.parameter_name] = parseFloat(item.default_value);
              break;
            case "string":
            case "date":
            case "datetime":
            case "time":
              data[item.parameter_name] = item.default_value;
              break;
            default:
              data[item.parameter_name] = item.default_value;
          }
        }
      }
    }

    function onKaydetClick() {
      if (!validateForm()) {
        return;
      }

      loadPanelVisible.value = true;

      hideToasts();

      if (isFileUpload) {
        if (data.guid == null) {
          loadPanelVisible.value = false;
          notify(
            {
              message:
                "Bu tabloda dosya yükleme işlemi için gerekli olan GUID kolonu bulunamadı!",
              type: "error",
              displayTime: 10000,
              minWidth: 150,
            },
            { position: "bottom center", direction: "up-push" }
          );
          return;
        }

        const formData = new FormData();
        data.files?.forEach((file) => {
          formData.append("files", file, file.name);
        });

        CoreService.post(
          "PostFileUpload/" + props.gridButtonId + "/" + data.guid,
          formData,
          true
        ).then((result) => {
          if (result.success) {
            emit("callGridRefresh");
            popupElement.value.instance.hide();
          } else {
            loadPanelVisible.value = false;
            notify(
              {
                message: result.message,
                type: "error",
                displayTime: 10000,
                minWidth: 150,
              },
              { position: "bottom center", direction: "up-push" }
            );
          }
        });
      } else if (isFileDelete) {
        if (data.table_row_guid == null || data.dyn_dosya_id == null) {
          loadPanelVisible.value = false;
          notify(
            {
              message:
                "Bu tabloda dosya silme işlemi için gerekli olan GUID ve DYN_DOSYA_ID kolonları bulunamadı!",
              type: "error",
              displayTime: 10000,
              minWidth: 150,
            },
            { position: "bottom center", direction: "up-push" }
          );
          return;
        }
        CoreService.post(
          "PostFileDelete/" + data.table_row_guid + "/" + data.dyn_dosya_id
        ).then((result) => {
          if (result.success) {
            emit("callGridRefresh");
            popupElement.value.instance.hide();
          } else {
            loadPanelVisible.value = false;
            notify(
              {
                message: result.message,
                type: "error",
                displayTime: 10000,
                minWidth: 150,
              },
              { position: "bottom center", direction: "up-push" }
            );
          }
        });
      } else if (isCsvImport) {
        const formData = new FormData();

        let newFormItem = {};
        for (const index in items) {
          const item = items[index];

          if (item.dx_editor_type == "dxFileUploader") {
            formData.append(
              "files",
              data[item.parameter_name],
              item.parameter_name
            );
          } else {
            newFormItem[item.parameter_name] = data[item.parameter_name];
          }
        }

        formData.append("jsonData", JSON.stringify(newFormItem));

        CoreService.post("PostCsvImport/" + props.gridButtonId, formData).then(
          (result) => {
            if (result.success) {
              emit("callGridRefresh");
              popupElement.value.instance.hide();
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );
      } else if (isGridDataImport) {
        const exportDataSource = CoreService.getDataSource(
          props.gridElement.instance.getDataSource().store(),
          props.gridElement.instance.getCombinedFilter()
        );
        exportDataSource.paginate(false);
        exportDataSource.load().done(function (exportDataResult) {
          const importFormData = new FormData();
          importFormData.append("gridData", JSON.stringify(exportDataResult));
          importFormData.append("formData", JSON.stringify(data));
          CoreService.post(
            "PostGridDataImport/" + props.gridButtonId,
            importFormData
          ).then((result) => {
            if (result.success) {
              popupElement.value.instance.hide();
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          });
        });
      } else if (isWebServiceGet) {
        loadPanelVisible.value = true;
        CoreService.post("GetWebService/" + props.gridButtonId, data).then(
          (result) => {
            if (result.success) {
              emit("callGridRefresh");
              popupElement.value.instance.hide();
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );
      } else if (isReportDisplay) {
        loadPanelVisible.value = true;
        CoreService.post("ReportDisplay/" + props.gridButtonId, data).then(
          (result) => {
            if (result.success) {
              //window.open(result.result, "_blank");
              popupElement.value.instance.hide();
              tabPanelStore.reportDisplay(
                result.result.url,
                result.result.report_name
              );
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );
      } else if (isMatbuForm) {
        CoreService.post("PostDataGridForm/" + props.gridButtonId, data).then(
          (result) => {
            if (result.success) {
              //matbu form eimzalı olacak ise tab içinde pdf dosyası açılmaz
              /*
              if (result.result.eimza == false) {
                //matbu formlarda kayıt işleminden sonra geriye
                //matbu formun dosya bilgileri gelir
                //yeni bir tab sayfasında pdf görüntüleme açılır

                console.log("result:",result);
                console.log("result.result:",result.result);

                tabPanelStore.pdfDisplay(
                  result.result.table_row_guid,
                  result.result.dyn_dosya_id,
                  result.result.dosya_adi
                );
              }
              */

              emit("callGridRefresh");
              popupElement.value.instance.hide();
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );
      } else if (isMatbuFormImzala) {
        CoreService.post("MatbuFormImzala/" + props.gridButtonId, data).then(
          (result) => {
            if (result.success) {
              emit("callGridRefresh");
              popupElement.value.instance.hide();
            } else {
              loadPanelVisible.value = false;
              notify(
                {
                  message: result.message,
                  type: "error",
                  displayTime: 10000,
                  minWidth: 150,
                },
                { position: "bottom center", direction: "up-push" }
              );
            }
          }
        );
      } else {

        /*ebys dosyası veya ebys eki var ise multipart aktif edilir, yoksa büyük boyutlu dosyalar sunucuya yüklenemiyor.*/
        const isMultiPart = props.dataGridFormResult.items.some(
          (item) =>
            item.dx_editor_type === "dynEbysDosyaUpload" ||
            item.dx_editor_type === "dynEbysEkDosyalarUpload"
        );

        CoreService.post(
          "PostDataGridForm/" + props.gridButtonId,
          data,
          isMultiPart
        ).then((result) => {
          if (result.success) {
            emit("callGridRefresh");
            popupElement.value.instance.hide();
          } else {
            loadPanelVisible.value = false;

            let msg = result.message;
            if (msg != null && msg.includes("23505:")) {
              msg =
                "GİRLEN BİLGİLERİ KONTROL EDİNİZ. BU BİLGİLER DAHA ÖNCE KAYIT EDİLMİŞ. " +
                msg;
            } else if (msg != null && msg.includes("23503:")) {
              msg =
                "BU KAYDA BAĞLI BAŞKA KAYITLAR VAR. ÖNCE BAĞLI KAYITLARI SİLİP SONRA BU KAYDI SİLEBİLİRSİNİZ. " +
                msg;
            }

            notify(
              {
                message: msg,
                type: "error",
                displayTime: 10000,
                minWidth: 150,
              },
              { position: "bottom center", direction: "up-push" }
            );
          }
        });
      }
    }

    function onKapatClick() {
      popupElement.value.instance.hide();
    }

    function onClickFormDesign() {
      isFormDesignerPopupVisible.value = true;
    }

    function getLookupFieldHelpText(item) {
      // if (
      //   item.cascade_form_item_id == null &&
      //   item.cascade2_form_item_id == null &&
      //   item.cascade3_form_item_id == null
      // )
      //   return item.help_text;

      let text = "";

      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade_form_item_id
          ).label_text +
          " ] ";
      }
      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade2_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade2_form_item_id
          ).label_text +
          " ] ";
      }
      if (
        items.find(
          (d) =>
            d.dyn_data_grid_button_form_item_id == item.cascade3_form_item_id
        ) != null
      ) {
        text +=
          " [ " +
          items.find(
            (d) =>
              d.dyn_data_grid_button_form_item_id == item.cascade3_form_item_id
          ).label_text +
          " ] ";
      }

      if (item.disable_rule_form_item_names?.length > 0) {
        item.disable_rule_form_item_names.forEach((ruleItemName) => {
          const ruleItem = items.find((d) => d.parameter_name == ruleItemName);
          if (ruleItem != null) text += " [ " + ruleItem.label_text + " ] ";
        });
      }

      if (text == "") return item.help_text ?? "";

      return text + "girilmelidir. " + (item.help_text ?? "");
    }

    function validateForm() {

      let dizi = [];
      items
        .filter(
          (i) =>
            i.visible &&
            i.is_required &&
            i.dx_data_type != "boolean" &&
            i.multiple_grid_cascade_form_item_id == null
        )
        .forEach((item) => {
          //visibleRule var ise oradaki durumda kontrol ediliyor
          const visibelRuleStatus =
            visibleRuleItem[item.parameter_name] ?? true;
          if (visibelRuleStatus) {
            if (item.dx_data_type == "number") {
              if (data[item.parameter_name] == null) {
                dizi.push(item.label_text);
              }
            } else if (item.dx_data_type == "object") {
              if (data[item.parameter_name] == null) {
                dizi.push(item.label_text);
              }
            } else {
              if (
                data[item.parameter_name] == null ||
                data[item.parameter_name] == ""
              ) {
                dizi.push(item.label_text);
              }
            }
          }
        });

      if (dizi.length > 0) {
        hideToasts();
      }

      dizi.forEach((item) => {
        notify(
          {
            message: "[ " + item + " ] alanı gereklidir.",
            type: "error",
            displayTime: 15000,
          },
          { position: "bottom right", direction: "up-push" }
        );
      });
      return dizi.length == 0;
    }

    function valueRuleItemInit() {
      const valueRuleItems = items.filter(
        (d) =>
          d.value_rule_form_item_names != null &&
          d.value_rule_form_item_names?.length > 0
      );
      valueRuleItems.forEach((ruleItem) => {
        //ilk değer atanıyor
        try {
          data[ruleItem.parameter_name] = eval(ruleItem.value_rule_js_function);
        } catch  {
          //console.log(ex);
        }

        ruleItem.value_rule_form_item_names.forEach((watchItemName) => {
          watch(
            () => data[watchItemName],
            () => {
              try {
                data[ruleItem.parameter_name] = eval(
                  ruleItem.value_rule_js_function
                );
              } catch  {
                  //console.log(ex);
              }
            }
          );
        });
      });
    }

    function visibleRuleItemInit(formInstance) {
      items
        .filter(
          (d) =>
            d.visible &&
            d.visible_rule_form_item_names != null &&
            d.visible_rule_form_item_names?.length > 0
        )
        .forEach((item) => {
          try {
            //ilk durum atanıyor
            const evalFirstResult = eval(item.visible_rule_js_function);
            formInstance.itemOption(
              item.parameter_name,
              "visible",
              evalFirstResult
            );
            visibleRuleItem[item.parameter_name] = evalFirstResult;
            item.visible_rule_form_item_names.forEach(
              (watchItemParameterName) => {
                watch(
                  () => data[watchItemParameterName],
                  () => {
                    const evalResult = eval(item.visible_rule_js_function);
                    formInstance.itemOption(
                      item.parameter_name,
                      "visible",
                      evalResult
                    );
                    visibleRuleItem[item.parameter_name] = evalResult;
                    //eğer kontrol sonucu false ise nesneye default değeri atanır
                    if (eval(item.visible_rule_js_function)) {
                      if (item.dx_data_type == "boolean") {
                        data[item.parameter_name] =
                          item.default_value == "true";
                      } else if (item.dx_data_type == "number") {
                        data[item.parameter_name] =
                          item.default_value == null
                            ? null
                            : parseFloat(item.default_value);
                      } else {
                        data[item.parameter_name] = item.default_value;
                      }
                    }
                  }
                );
              }
            );
          } catch (error) {
            console.log(error);
          }
        });
    }

    let visibleRuleItem = {};
    onMounted(() => {
      const formInstance = formElement.value.instance;

      visibleRuleItemInit(formInstance);
      valueRuleItemInit();

      //eğer form üzerinde ki tüm nesneler gizli ise otomatik kaydet yapılır (silme operasyonu hariç) ve form kapatılır
      if (isOtoKaydetVeKapat) {
        setTimeout(() => {
          onKaydetClick();
        }, 200);
      }
    });

    return {
      refProps,
      elementId,
      isFullScreen,
      guid,
      tabPageId,
      button,
      loadPanelVisible,
      groups,
      tabs,
      tabPages,
      items,
      columns,
      popupElement,
      formElement,
      data,
      masterRelationItem,
      masterRelationRowItem,
      isFileUpload,
      isCsvImport,
      onKaydetClick,
      onKapatClick,
      formDesignerVisible,
      isFormDesignerPopupVisible,
      isMatbuForm,
      onClickFormDesign,
      getLookupFieldHelpText,
      formDesignerPopupStyle: {
        class: "form-designer-popup-content-background",
      },
    };
  },

  methods: {
    popupOnHidden() {
      this.loadPanelVisible = true;
      this.$emit("callPopupHiddenEvent");
    },
    popupAnimationAttribute() {
      return {
        show: {
          type: "fade",
          duration: 150,
        },
        hide: {
          type: "fade",
          duration: 150,
          to: {
            opacity: 0,
            scale: 0.55,
          },
          from: {
            opacity: 1,
            scale: 1,
          },
        },
      };
    },
  },
};
</script>
