<template :key="guid">
  <DxFileUploader
    ref="ebysFileUploaderElement"
    selectButtonText="YÜKLEMEK İÇİN EK DOSYLAR SEÇİN"
    uploadMode="useForm"
    :multiple="true"
    @valueChanged="onValueChanged"
  />
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxFileUploader,
  },

  props: {
    buttonFormItem: { type: Object },
    buttonFormItemData: { type: Object },
    formDataItem: { type: Object },
  },

  setup(props) {
    const guid = ref(CoreService.getRandomGuid());
    const ebysFileUploaderElement = ref();
    let dataItem = reactive(props.formDataItem);
    const buttonItem = reactive(props.buttonFormItem);

    function onValueChanged(e) {      

      dataItem[buttonItem.parameter_name] = [];
      for (let i in e.value) {
        const reader = new FileReader();
        reader.readAsDataURL(e.value[i]);
        reader.onloadend = function () {
        
          dataItem[buttonItem.parameter_name].push({
            name: e.value[i].name,
            type: e.value[i].type,
            base64: reader.result,
          });
        };
      }
     
    }

    return {
      guid,
      ebysFileUploaderElement,
      buttonItem,
      onValueChanged,
    };
  },
};
</script>

<style></style>
