<template>
  <img v-if="imageSource" :src="imageSource" :style="imgStyle" />
  <button class="btn btn-outline-danger btn-sm" @click="resetImageUpload()">
    <i class="bi bi-x-circle"></i>
  </button>
  <DxFileUploader
    ref="fileUploaderRef"
    :multiple="false"
    :allowed-file-extensions="allowedFileExtensions"
    upload-mode="useForm"
    @valueChanged="onValueChanged"
  />
</template>

<script>
import { reactive } from "@vue/reactivity";
import { ref, onMounted } from "vue";
import { DxFileUploader } from "devextreme-vue/file-uploader";

export default {
  components: {
    DxFileUploader,
  },
  props: {
    pItem: { type: Object },
    pFormData: { type: Object },
  },
  setup(props) {
    const item = reactive(props.pItem);
    const data = reactive(props.pFormData);
    const fileUploaderRef = ref();
    const allowedFileExtensions = [".jpg", ".jpeg", ".png"];
    const imageSource = ref();
    const imgStyle = ref("max-height:100px;");

    if (item.height) {
      imgStyle.value = "max-height:" + item.height + "px;";
    }

    function resetImageUpload() {
      fileUploaderRef.value.instance.reset();
    }

    function onValueChanged(e) {
      
      if (e.value.length == 0) {
        imageSource.value = null;
        data[item.parameter_name] = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(e.value[0]);
      reader.onloadend = function () {
        imageSource.value = reader.result;
        data[item.parameter_name] = reader.result;
      };
    }
    onMounted(() => {
      imageSource.value = data[item.parameter_name + "_base64"];
      data[item.parameter_name] = data[item.parameter_name + "_base64"];
    });

    return {
      item,
      data,
      fileUploaderRef,
      allowedFileExtensions,
      imageSource,
      imgStyle,
      resetImageUpload,
      onValueChanged,
    };
  },
};
</script>
