<template>
  <button
    class="btn btn-primary"
    @click="switchWorkingMode()"
    v-text="switchText"
  ></button>
  <div
    style="
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1px 1px 45px 1px;
      margin-top: 40px;
    "
  >
    <!-- <br/> -->

    <DxDashboardControl
      ref="dashboardControl"
      style="height: 100%; width: 100%"
      workingMode="Designer"
      :endpoint="endPoint"
      :fetchRemoteService="{ headers: { token: token } }"
      @beforeRender="onBeforeRender"
    >
    </DxDashboardControl>
  </div>
</template>

<script>
import { ref } from "vue";
import { DxDashboardControl } from "devexpress-dashboard-vue";
import { TextBoxItemEditorExtension } from "devexpress-dashboard/designer/text-box-item-editor-extension";
import CoreService from "@/services/core.service";

export default {
  components: {
    DxDashboardControl,
  },
  props: {},
  setup() {
    const dashboardControl = ref();
    const endPoint = CoreService.getApiUrl() + "dashboard";
    const token = CoreService.getToken();
    const guid = ref(CoreService.getRandomGuid());
    const switchText = ref("Switch to Viewer");

    function switchWorkingMode() {
      var workingMode = dashboardControl.value.instance.isDesignMode();
      if (workingMode == true) {
        dashboardControl.value.instance.switchToViewer();
        switchText.value = "Switch to Designer";
      } else {
        dashboardControl.value.instance.switchToDesigner();
        switchText.value = "Switch to Viewer";
      }
    }

    function onBeforeRender(e) {
      e.component.registerExtension(
        new TextBoxItemEditorExtension(e.component)
      );
    }

    return {
      guid,
      dashboardControl,
      endPoint,
      token,
      switchWorkingMode,
      onBeforeRender,
      switchText,
    };
  },
};
</script>
