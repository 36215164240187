import { defineStore } from "pinia";
import CoreService from '@/services/core.service';

const KEY_USER = "user";

export const useAuthStore = defineStore('auth',{  
  state: () => ({
    user: JSON.parse(localStorage.getItem(KEY_USER)),    
  }),
  getters: {
    getUser(state) {      
      return state.user;
    },
    formDesignerVisible(state) {      
      return state.user?.formDesignerVisible === true;
    },
    dashboardDesignerVisible(state) {      
      return state.user?.dashboardDesignerVisible === true;
    },
    reportDesignerVisible(state) {      
      return state.user?.reportDesignerVisible === true;
    },
    getIsLogin(state) {      
      return state.user !== null;
    },
  },
  actions: {
    async login(kod, sifre) {
      const result = await CoreService.post('UserLogin',{kod, sifre});       
      if (result.success) {        
        this.user = result.result;
        localStorage.setItem(KEY_USER, JSON.stringify(result.result));          
      }     
      return result;
    },

    async logout() {
      this.user = null;
      localStorage.clear();
    },
  },
});