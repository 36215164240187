<template>
  <p>aaa</p>
  <div ref="richEditElement"></div>
</template>

<script>
import {
  create,
  createOptions,
  ViewType,
  RichEditUnit,
} from "devexpress-richedit";

import { ref, onMounted   } from "vue";


export default {
  
  components: {},

  setup() {
    
    const richEditElement = ref();

    onMounted(() => {
      const options = createOptions();
      options.unit = RichEditUnit.Centimeter;
      options.view.viewType = ViewType.PrintLayout;

      options.exportUrl = "https://siteurl.com/api/";
      options.readOnly = false;
      options.width = "100vh";
      options.height = "100vh";

      create(richEditElement.value, options);

     
    });  

    return {
      richEditElement,
    };
  },


 
};
</script>

