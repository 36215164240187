<template :key="guid">
  <iframe :style="style" :src="src"></iframe> 
</template>

<script>

import { ref  } from "vue";
import CoreService from "@/services/core.service";

export default {
  props: {
    report_url: {
      type: String,
      required: true,
    }    
  },
  setup(props) {   
    const guid = ref(CoreService.getRandomGuid());   
    const src = ref(props.report_url);
    const style = ref("width: 100%; border: 0; height:"+(window.outerHeight- 150)+"px;");
    const isLoaded = ref(false);

    CoreService.post('ReportDesigner').then((result) => {      
      src.value = result.result;
      isLoaded.value = true;    
    });


    return {
      guid,    
      src,
      style    
    };
  },
  components: {},
};
</script>
