<template :key="guid">
  <DxPopup
    :key="'MasterDetailPopup_' + guid"
    v-model:visible="isMasterDetailPopupVisible"
    :showCloseButton="true"
    :container="'#dynTabPage' + tabPageId"
    :dragEnabled="false"
    width="99%"
    height="95%"
    :title="title"
    @showing="onShowing"
    @hiding="onHiding"
  >
    <DxPosition
      at="center"
      my="center"
      collision="fit"
      offset="0 25"
      :of="'#dynTabPage' + tabPageId"
    />
    <div>
      <MasterDetailGridComponent
            :masterElementId="masterElementId"
            :masterRowItem="masterRowItem"
            :pageId="tabPageId"          
          />
    </div>
  </DxPopup>
</template>

<script>
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import ScrollView from "devextreme/ui/scroll_view";
import CoreService from "@/services/core.service";
import { ref } from "@vue/reactivity";

export default {
  components: {
    DxPopup,
    DxPosition,
  },
  props: {
    pageId: Number,
    pTitleFieldName:String,
    pMasterElementId: Number,
    pMasterRowItem: Object,
    pMasterDetailPopupVisible: Boolean,
  },
  setup(props, { emit }) {
    const guid = ref(CoreService.getRandomGuid());
    const tabPageId = ref(props.pageId);
    const title = ref(props.pTitleFieldName == null ? "MASTER DETAY" : props.pMasterRowItem[props.pTitleFieldName]);
    const masterElementId = ref(props.pMasterElementId);
    const masterRowItem = ref(props.pMasterRowItem);
    const isMasterDetailPopupVisible = ref(props.pMasterDetailPopupVisible);

    let scrollOffset = null;
    function onShowing() {
      const scrollViewElement = ScrollView.getInstance(
        document.getElementById("pageLayoutScrollView")
      );
      scrollOffset = scrollViewElement.scrollOffset();

      const divElement = document.getElementById(
        "dynTabPage" + tabPageId.value
      );
      divElement.style.height = "100vh";
    }

    function onHiding() {
      const divElement = document.getElementById(
        "dynTabPage" + tabPageId.value
      );
      divElement.style.removeProperty("height");
      const scrollViewElement = ScrollView.getInstance(
        document.getElementById("pageLayoutScrollView")
      );
      scrollViewElement.scrollTo(scrollOffset);      
      emit("callMasterPopupHiding");
    }

    return {
      guid,
      title,
      tabPageId,
      masterElementId,
      masterRowItem,
      isMasterDetailPopupVisible,
      onShowing,
      onHiding,
    };
  },
};
</script>
