<template :key="guid">
  <DxTextBox
    v-if="item.dx_editor_type == 'dxTextBox'"
    v-model:value="data[item.parameter_name]"
    :readOnly="item.readonly"
    :disabled="disabled"
    :visible="visible"
    :mask="item.mask"
    @valueChanged="onValueChanged"
  />

  <DxTextArea
    v-if="item.dx_editor_type == 'dxTextArea'"
    v-model:value="data[item.parameter_name]"
    :readOnly="item.readonly"
    :disabled="disabled"
    :visible="visible"
    @valueChanged="onValueChanged"
  />

  <PopupFormItemHtmlEditorComponent
    v-if="item.dx_editor_type == 'dxHtmlEditor'"
    :pItem="item"
    :pFormData="data"
    :readOnly="item.readonly"
    :disabled="disabled"
  />

  <PopupFormItemMultipleGridComponent
    v-if="item.dx_editor_type == 'dynMultipleGridItem'"
    :pItem="item"
    :pFormData="data"
    :pFields="fields"
  />

  <PopupFormItemImageEditorComponent
    v-if="item.dx_editor_type == 'dynImage'"
    :pItem="item"
    :pFormData="data"
    :readOnly="item.readonly"
    :disabled="disabled"
  />

  <DxNumberBox
    v-if="item.dx_editor_type == 'dxNumberBox'"
    v-model:value="data[item.parameter_name]"
    :readOnly="item.readonly"
    :disabled="disabled"
    :visible="visible"
    :format="item.format"
  />

  <DxCheckBox
    v-if="item.dx_editor_type == 'dxCheckBox'"
    v-model:value="data[item.parameter_name]"
    :readOnly="item.readonly"
    :disabled="disabled"
    :visible="visible"
    :text="item.label_text"
  />

  <DxDateBox
    v-if="item.dx_editor_type == 'dxDateBox'"
    v-model:value="data[item.parameter_name]"
    :readOnly="item.readonly"
    :disabled="disabled"
    :visible="visible"
    :type="item.dx_data_type"
    :displayFormat="item.format"
    :interval="15"
    dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
    :openOnFieldClick="true"
  />

  <EbysDosyaYuklePopupComponent
    v-if="item.dx_editor_type == 'dynEbysDosyaUpload'"
    :key="'popupEbysDosyaYukle_' + item.parameter_name"
    :buttonFormItem="item"
    :buttonFormItemData="items"
    :formDataItem="data"
  />

  <EbysEkDosyaYuklePopupComponent
    v-if="item.dx_editor_type == 'dynEbysEkDosyalarUpload'"
    :key="'popupEbysEkDosyaYukle_' + item.parameter_name"
    :buttonFormItem="item"
    :buttonFormItemData="items"
    :formDataItem="data"
  />
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxCheckBox from "devextreme-vue/check-box";
import DxDateBox from "devextreme-vue/date-box";
import DxNumberBox from "devextreme-vue/number-box";
import CoreService from "@/services/core.service";
import { ref, reactive } from "@vue/reactivity";
import { watch } from "vue";

export default {
  components: {
    DxTextBox,
    DxTextArea,
    DxCheckBox,
    DxDateBox,
    DxNumberBox,
  },
  props: {
    pItem: { type: Object },
    pFormData: { type: Object },
    pFields: { type: Object },
  },

  setup(props) {
    const guid = CoreService.getRandomGuid();
    const item = reactive(props.pItem);
    const fields = reactive(props.pFields);

    const disabled = ref(false);
    const visible = ref(item.visible);

    //data değişken adı [disable_rule_js_function] içinde kullanılıyor.
    //kesinlikle değişken adı değişmeyecek!!!
    let data = reactive(props.pFormData);

    if (item.disable_rule_form_item_names?.length > 0) {
      try {
        disabled.value = eval(item.disable_rule_js_function);
        item.disable_rule_form_item_names.forEach((watchItemParameterName) => {
          watch(
            () => data[watchItemParameterName],
            () => {
              disabled.value = eval(item.disable_rule_js_function);

              //bu field multiple gride bağlı ise disable/enable değişiminde değeri değişmez
              //çünkü değerini multiple grid eventları
              if (item.multiple_grid_cascade_form_item_id == null) {
                //eğer kontrol sonucu false ise nesneye default değeri atanır
                if (eval(item.disable_rule_js_function)) {
                  if (item.dx_data_type == "boolean") {
                    data[item.parameter_name] = item.default_value == "true";
                  } else if (item.dx_data_type == "number") {
                    data[item.parameter_name] =
                      item.default_value == null
                        ? null
                        : parseFloat(item.default_value);
                  } else {
                    data[item.parameter_name] = item.default_value;
                  }
                }
              }
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    }

    /*
    //visible rule
    if (item.visible_rule_form_item_names?.length > 0) {
      try {
        visible.value = eval(item.visible_rule_js_function);
        item.visible_rule_form_item_names.forEach((watchItemParameterName) => {        
          watch(
            () => data[watchItemParameterName],
            () => {              
              visible.value = eval(item.visible_rule_js_function);              
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    }

    */

    function onValueChanged() {
      if (item.is_uppercase && data[item.parameter_name] != null) {
        data[item.parameter_name] =
          data[item.parameter_name].toLocaleUpperCase("tr-TR");
      }
    }

    return {
      guid,
      item,
      data,
      fields,
      disabled,
      visible,
      onValueChanged,
    };
  },
};
</script>
