import { createRouter, createWebHashHistory } from "vue-router";
import { useAuthStore } from "@/services/auth.store";

import PageLayout from "@/layouts/page-layout";
import simpleLayout from "./layouts/simple-layout";
import Giris from "@/views/giris-page";

const router = new createRouter({
  routes: [
    {
      path: "/login",
      name: "login",
      meta: {
        layout: simpleLayout,
        title: "Login",
      },
      component: () => import("@/views/login-page"),
    },    
    {
      path: "/",
      name: "giris",
      meta: {
        requiresAuth: false,
        layout: PageLayout,
      }, 
      component:Giris     
    },    

    {
      path: "/:pathMatch(.*)*",
      redirect: "/",
    },
  ],
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const store = useAuthStore();
  if (to.path !== "/login" && !store.getIsLogin) {
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
