<template>
  <DxButton
    type="normal"
    icon="contentlayout"
    :text="elementId"
    :visible="formDesignerVisible"
    @click="onClickFormDesign"
  />
  <div v-if="isReLoadComponent">
  <DxForm ref="formElement" v-show="items.length > 0" :formData="formDataItem">
    <slot v-for="g in groups" :key="g">
      <DxGroupItem
        :caption="g.caption"
        :colCount="g.col_count"
        :colSpan="g.col_span"
      >
        <slot
          v-for="item in items.filter(
            (d) =>
              d.dyn_data_grid_parametre_form_id ==
              g.dyn_data_grid_parametre_form_id
          )"
          :key="'roottemp_' + item.parameter_name"
        >
            <DxItem v-if="!item.is_lookup && item.dx_editor_type != 'dxCalendar'"
              :dataField="item.parameter_name"
              :visible="item.visible"
              :visibleIndex="item.visible_index"
              :label="{ text: item.label_text, visible: item.label_visible }"
              :editorType="item.dx_editor_type"
              :isRequired="item.dx_data_type == 'boolean' ? false : item.is_required"
              :helpText="item.help_text"
              :colSpan="item.col_span"
              :editorOptions="editorOptions(item)"
            />

            <DxItem v-if="!item.is_lookup && item.dx_editor_type == 'dxCalendar'"
              :dataField="item.parameter_name"
              :visible="item.visible"
              :visibleIndex="item.visible_index"
              :label="{ text: item.label_text, visible: false }"
              :isRequired="item.is_required"
              :helpText="item.help_text"
              :colSpan="item.col_span"
            >
              <ParametreFormItemCalendarComponent
                :key="'parametreCalendar_' + item.parameter_name"
                :parametreFormItem="item"
                :parametreFormItemData="items"
                :formDataItem="formDataItem"
              />
            </DxItem>

            <DxItem v-if="item.is_lookup"
              :dataField="item.parameter_name"
              :visible="item.visible"
              :visibleIndex="item.visible_index"
              :label="{ text: item.label_text, visible: item.label_visible }"
              :isRequired="item.is_required"
              :helpText="item.help_text"
              :colSpan="item.col_span"
            >
              <DropDownParametreComponent
                :key="'parametreDdb_' + item.parameter_name"
                :parametreFormItem="item"
                :parametreFormItemData="items"
                :formDataItem="formDataItem"
              />
            </DxItem>
        </slot>
      </DxGroupItem>
    </slot>
  </DxForm>
</div>
  <DxPopup
    :key="'ParametreFormDesignerPopup_' + guid"
    v-model:visible="isFormDesignerPopupVisible"
    :showCloseButton="true"
    :wrapperAttr="formDesignerPopupStyle"
    :title="'ELEMENT ID = ' + elementId"
    :container="'#dynTabPage' + tabPageId"
    :dragEnabled="false"
    width="100%"
    height="97%"
    @showing="onShowing"
    @hiding="onHiding"
  >
    <DxPosition
      at="center"
      my="center"
      collision="fit"
      :of="'#dynTabPage' + tabPageId"
    />
    <div>
      <FormDesignerComponent
        v-if="isFormDesignerPopupVisible"
        tip="P"
        :formId="elementId"
      />
    </div>
  </DxPopup>
</template>

<script>
import { DxForm, DxGroupItem, DxItem } from "devextreme-vue/form";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";
import ScrollView from "devextreme/ui/scroll_view";
import { useAuthStore } from "@/services/auth.store";
import CoreService from "@/services/core.service";
import { ref, reactive } from "vue";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxItem,
    DxButton,
    DxPopup,
    DxPosition,
  },
  props: {
    pageId: Number,
    parametreFormData: Object,
    parametreForms: {
      type: Object,
    },
    parametreFormItems: {
      type: Object,
    },
    gridItem: Object,
    masterRowItem: Object,    
  },
  setup(props) {
    const guid = ref(CoreService.getRandomGuid());
    const isReLoadComponent = ref(true);

    const authStore = useAuthStore();
    const formDesignerVisible = ref(authStore.formDesignerVisible);
    const isFormDesignerPopupVisible = ref(false);
    const isFormLoaded = ref(true);
    let formDataItem = reactive(props.parametreFormData ?? {});
    const formElement = ref();
    const tabPageId = ref(props.pageId);
    const elementId = ref(props.parametreForms[0].dyn_data_grid_id);
    const buttons = reactive(props.parametreFormButtons);
    const groups = reactive(props.parametreForms);
    const items = reactive(props.parametreFormItems);    
    
    initFormDataItem();

    function initFormDataItem() {     
      
      for (let item of items) {
        //eğer detail grid içinden çağrılan bir parametre formu ise
        if (props.masterRowItem != null) {
          formDataItem[item.parameter_name] = props.masterRowItem[item.parameter_name] ?? getDefaultValue(item);
        } else {
          formDataItem[item.parameter_name] = getDefaultValue(item);
        }
      }
    }  

    function getDefaultValue(obj) {
      if (obj.default_value == null) {
        return null;
      }

      if (obj.dx_data_type == "boolean") {
        return obj.default_value === "true";
      }

      if (obj.dx_data_type == "number") {
        return parseFloat(obj.default_value);
      }
      return obj.default_value;
    }

    function onClickFormDesign() {
      isFormDesignerPopupVisible.value = true;
    }

    let scrollOffset = null;
    function onShowing() {
      const scrollViewElement = ScrollView.getInstance(
        document.getElementById("pageLayoutScrollView")
      );
      scrollOffset = scrollViewElement.scrollOffset();

      const divElement = document.getElementById(
        "dynTabPage" + tabPageId.value
      );
      divElement.style.height = "100vh";
    }

    function onHiding() {
      const divElement = document.getElementById(
        "dynTabPage" + tabPageId.value
      );
      divElement.style.removeProperty("height");

      const scrollViewElement = ScrollView.getInstance(
        document.getElementById("pageLayoutScrollView")
      );
      scrollViewElement.scrollTo(scrollOffset);
    }   

    //bu fonksiyon grid-page.vue içinde gridin toolbar üzerinde bulunan "filtre temizle" butonu tetiklendiği zaman çağrılmaktadır.
    //aksi durumda filtreler temizleniyor ama parametre fomr üzerindeki değerler temizlenmiyordu.
    const setDefaultValues = () => {
      isReLoadComponent.value = false;
      isReLoadComponent.value = true;
      initFormDataItem();      
    }
    const callSetDefaultValuesFunction = ref(setDefaultValues);

    return {
      guid,
      isReLoadComponent,
      tabPageId,
      elementId,
      formDesignerVisible,
      isFormDesignerPopupVisible,
      isFormLoaded,
      groups,
      items,
      buttons,
      formElement,
      formDataItem,
      onClickFormDesign,
      onShowing,
      onHiding,
      formDesignerPopupStyle: {
        class: "form-designer-popup-content-background",
      },
      callSetDefaultValuesFunction
    };
  },

  methods: {
    editorOptions(item) {
      let e = {
        mask: item.mask,
        readOnly: item.readonly,
        format: item.format,
        displayFormat: item.format,
        type: item.dx_data_type,
        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
        interval: 15,
      };

      // if(item.dx_data_type == "date" || item.dx_data_type == "datetime" || item.dx_data_type == "time")
      // {
      //   e.type = item.dx_data_type;
      // }

      return e;
    },
  },
};
</script>
