<template :key="guid">
  <iframe :style="style" :src="src"></iframe> 
</template>

<script>

import { ref  } from "vue";
import CoreService from "@/services/core.service";

export default {
  props: {
    dosya_guid: {
      type: String,
      required: true,
    },
    dosya_id: {
      type: Number,
      required: true,
    }        
  },
  setup(props) {   
    const guid = ref(CoreService.getRandomGuid());   
    const isLoaded = ref(false);
    const src = ref("");
    const style = ref("width: 100%; border: 0; height:"+(window.outerHeight- 150)+"px;");    

    const decodeBase64 = (base64Data) => {
      const binaryString = window.atob(base64Data);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    };

    CoreService.get('GetFileBase64/'+props.dosya_guid+'/'+props.dosya_id).then((result) => {   
      
      const pdfBytes = decodeBase64(result);
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      src.value = URL.createObjectURL(blob);
      isLoaded.value = true;
      
      // src.value = 'data:application/pdf;base64,' + result;
      // isLoaded.value = true;    
    });

    return {
      guid,    
      isLoaded,
      src,
      style
    
    };
  },
  components: {},
};
</script>
