<template>
<DxFileUploader            
            selectButtonText="YÜKLEMEK İÇİN DOSYA SEÇİN"      
            uploadMode="useForm"          
            :multiple="true"
            :accept="acceptFileExtensions"
            @valueChanged="onValueChanged"
          /> 
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { DxFileUploader } from "devextreme-vue/file-uploader";


export default {
  components: {
    DxFileUploader
  },

  props: {    
    formDataItem: { type: Object },
    buttonItem: { type: Object }
  },

  setup(props) {   
    let dataItem = reactive(props.formDataItem);
    const acceptFileExtensions= ref(props.buttonItem.accept_file_extensions);
   function onValueChanged(e){
      dataItem["files"] = e.value;    
   }
    return {
      acceptFileExtensions,
      onValueChanged     
    };
  },
};
</script>

<style></style>
