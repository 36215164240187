<template>
  
  <DxForm :colCount="formItem.colCount">
    <DxGroupItem
      v-for="g in groupItems"
      :key="g.groupId"
      :caption="g.groupId + ' - ' + g.caption"
      :colCount="g.colCount"
      :colSpan="g.colSpan"
      :visibleIndex="g.visibleIndex"
      :cssClass="'dyn-form-group dyn-id-' + g.groupId"
    >
      <slot
        v-for="item in items.filter(
          (d) => d.groupId == g.groupId && d.visible == true
        )"
        :key="item.itemId"
      >
        <DxSimpleItem
          :visibleIndex="item.visibleIndex"
          :visible="item.visible"
          :colSpan="item.colSpan"
        >
          <template #default>
            <div
              :draggable="true"              
              @dragstart="onDragStart($event, item.itemId)"             
              class="form-item dx-card dyn-column-item"
              :data-item-id="item.itemId"
            >
              <DxBox :height="55" direction="col" width="100%">
                <DxItem :ratio="1">
                  <template #default>
                    <DxBox direction="row" width="100%">
                      <DxItem :ratio="5">
                        <template #default>
                          <DxTextBox
                            v-model:value="item.labelText"
                            height="30px"
                          />
                        </template>
                      </DxItem>
                      <DxItem :ratio="0" :base-size="85">
                        <template #default>
                          <DxNumberBox
                            v-model:value="item.colSpan"
                            labelMode="static"
                            text="colSpan"
                            :min="1"
                            height="30px"
                            width="90px"
                            :show-spin-buttons="true"
                          />
                        </template>
                      </DxItem>
                    </DxBox>
                  </template>
                </DxItem>
                <DxItem :ratio="0" :base-size="20">
                  <template #default>
                    <DxBox direction="row" width="100%">
                      <DxItem :ratio="1">
                        <template #default>
                          <table>
                            <tr>
                              <td>[{{ item.parameterName }}]</td>
                              <td>
                                <small style="font-style: italic"
                                  >#{{ item.dbDataType }} </small
                                >
                              </td>
                              <td>
                                <i
                                  v-show="item.isLookUp"
                                  style="color: red"
                                  class="icon dx-icon-menu"
                                ></i>
                              </td>
                            </tr>
                          </table>
                        </template>
                      </DxItem>
                    </DxBox>
                  </template>
                </DxItem>
              </DxBox>
            </div>
          </template>
        </DxSimpleItem>
      </slot>
    </DxGroupItem>
  </DxForm>

</template>

<script>
import { ref } from "vue";
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxTextBox from "devextreme-vue/text-box";
import { DxBox, DxItem } from "devextreme-vue/box";

export default {
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxTextBox,
    DxNumberBox,
    DxBox,
    DxItem,
   
  },
  props: {
    pFormItem: Object,
    pGroupItems: Object,
    pItems: Object,
  },
  setup(props) {
    const formItem = ref(props.pFormItem);
    const groupItems = ref(props.pGroupItems);
    const items = ref(props.pItems);

    function onDragStart(e, itemId) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("itemId", itemId);
    }

    return {
      formItem,
      groupItems,
      items,
      onDragStart,
    };
  },
};
</script>