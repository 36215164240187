<template> 
  <DxForm
        :formData="formItem"
        labelMode="floating"
        :showColonAfterLabel="true"
        :labelLocation="left"
        :colCount="4"
      >
        <DxSimpleItem data-field="popupWidth" />
        <DxSimpleItem data-field="popupHeight" />
        <DxSimpleItem data-field="colCount" />        
      </DxForm>
</template>

<script>
import { ref } from "vue";
import { DxForm, DxSimpleItem } from "devextreme-vue/form";

export default {
  components: { DxForm, DxSimpleItem },
  props: {
    pFormItem: Object,
    pGroupItems: Object,
  },
  setup(props) {
    const formItem = ref(props.pFormItem);
    return {
      formItem,
     
    };
  },
};
</script>
